import React, {useCallback, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {useDispatch, useSelector} from "react-redux";
import {reminderAddReq, reminderCloseAction, reminderUpdateReq} from "../../store/Matters/mattersSlice";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {Controller, useForm} from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import {useParams} from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'white',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
};

const handleRemindersType = {
    type_1: "Remind the client",
    type_2: "Clarify the information with the client",
    type_3: "Consult with the Sydney team"
}

const ReminderModal = () => {
    const { open: reminderOpened, reminderId, ...reqBody } = useSelector(state => state.matters.reminderOpened) || {};
    const reminderInfo = useSelector(state => state.matters.reminderInfo)
    const dispatch = useDispatch();
    const { matterId } = useParams();
    const {
        control,
        setValue,
        handleSubmit,
        getValues,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    useEffect(() => {
        if (reminderId) {
            setValue("reminder", reminderInfo.content);
            setValue("reminderType", reminderInfo.type);
        }
    }, [reminderId, reminderInfo, setValue]);

    const onSubmit = useCallback((data) => {
        if (!!getValues().reminder && !!getValues().reminderType) {
            if (!reminderId) {
                dispatch(reminderAddReq({
                    ...reqBody,
                    content: data.reminder,
                    type: data.reminderType,
                    matter_id: +matterId
                }))
            } else {
                dispatch(reminderUpdateReq({
                    ...reqBody,
                    content: data.reminder,
                    type: data.reminderType,
                    matter_id: +matterId,
                    id: reminderId
                }))
            }
            dispatch(reminderCloseAction())
        }
    }, [dispatch, getValues, matterId, reminderId, reqBody])

    return (
        <Modal
            open={reminderOpened}
            onClose={() => dispatch(reminderCloseAction())}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{ sx: { bgcolor: 'rgba(0, 0, 0, 0.3)' } }}
        >
            <Box sx={style}>
                <Stack
                   gap={5}
                   as="form"
                   onSubmit={handleSubmit(onSubmit)}
                >
                    <Stack gap={2}>
                        <Typography sx={{ display: "flex", gap: "5px" }}>
                            <Typography sx={{ color: "red" }}>*</Typography>Description
                        </Typography>
                        <Controller
                            control={control}
                            name="reminder"
                            render={({field: {onChange, value, ref, onBlur}}) => (
                                <textarea
                                    name="reminder"
                                    cols="30"
                                    rows="10"
                                    placeholder="Reminder"
                                    style={{ resize: "none" }}
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    ref={ref}
                                />
                            )}/>
                    </Stack>
                    <Stack gap={2}>
                        <Typography>Label:</Typography>
                        <FormControl sx={{ m: 1, minWidth: 180, margin: 0 }} size="small">
                            <InputLabel id="demo-select-small-label">Select Label</InputLabel>
                            <Controller
                                name="reminderType"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        label="Member"
                                        value={field.value}
                                    >
                                        {Object.keys(handleRemindersType).map(elem => (
                                            <MenuItem key={elem} value={elem}>
                                                {handleRemindersType[elem]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Stack>
                    <LoadingButton
                        sx={{
                            backgroundColor: "primary.dark",
                            color: "common.white",
                            p: "8px 20px",
                            borderRadius: "4px",
                            width: "max-content"
                        }}
                        type="submit"
                    >
                        {reminderId ? "Edit" : "Add"} reminder
                    </LoadingButton>
                </Stack>
            </Box>
        </Modal>
    );
}

export default ReminderModal;