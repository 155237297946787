import {bgGradient} from "../../theme/css";
import React, {useCallback, useState} from "react";
import {alpha, useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {Card, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import LoadingButton from '@mui/lab/LoadingButton';
import {Controller, useForm} from "react-hook-form";
import { resetPasswordReq } from "../../store/Auth/authSlice";
import { useDispatch } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Iconify from "../ui-kit/Iconify";
import {useRouter} from "../../usefuls/hooks";

const ForgotPassword = () => {
    const theme = useTheme();
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const dispatch = useDispatch();
    const router = useRouter();
    const { handleSubmit, control } = useForm({
        defaultValues: {
            email: "",
            password: "",
            password_confirmation: ""
        },
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    const onSubmit = useCallback(async (data) => {
        const passwordOut = await dispatch(resetPasswordReq({
            ...data,
            token: window.location.search.split("?token=", 2)[1]
        }));
        if (passwordOut.error?.message !== "Rejected") {
            router.push("/signin");
        }
    }, [dispatch, router])

    return (
        <Box
            sx={{
                ...bgGradient({
                    color: alpha(theme.palette.background.default, 0.9),
                    imgUrl: '/assets/background/overlay_4.jpg',
                }),
                height: 1,
            }}
        >
            <img
                src="/images/Karni.svg" alt="Karni SVG"
                style={{width: "52px", height: "40px", display: 'inline-flex', margin: "20px"}}
            />
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{height: "80vh"}}
                as="form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Card sx={{ p: 5, width: 1, maxWidth: 420 }}>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h4" sx={{mt: 2, mb: 5}}>New password</Typography>
                    </Box>
                    <Stack spacing={3}>
                        <Controller
                            control={control}
                            name="email"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TextField
                                    name="email"
                                    label="Email address"
                                    type="email"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    ref={ref}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="password"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TextField
                                    name="password"
                                    label="Reset password"
                                    type={showResetPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowResetPassword(!showResetPassword)} edge="end">
                                                    <Iconify icon={showResetPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    ref={ref}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="password_confirmation"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TextField
                                    name="password_confirmation"
                                    label="Confirm Password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                                                    <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    ref={ref}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                    </Stack>
                    <LoadingButton
                        sx={{my: 3}}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="inherit"
                    >
                        Submit
                    </LoadingButton>
                </Card>
            </Stack>
        </Box>
    )
}

export default ForgotPassword;