import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TableContainer from "@mui/material/TableContainer";
import PersonIcon from '@mui/icons-material/Person';
import ListItemButton from "@mui/material/ListItemButton";
import Box from "@mui/material/Box";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {editMyProfileDataReq, editUserByIdReq, getMyProfileDataReq, userByIdReq} from "../../store/Users/usersSlice";
import {useRouter} from "../../usefuls/hooks";
import {useParams} from "react-router-dom";

export default function EditEmployee() {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.users.currentUser);
    const router = useRouter();
    const { id: userId } = useParams();
    const {
        handleSubmit,
        control,
        setValue,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })
    useEffect(() => {
        if (userId) {
            dispatch(userByIdReq(userId));
        } else {
            dispatch(getMyProfileDataReq())
        }
    }, [dispatch, userId]);

    const { fields } = useFieldArray({ control, name: "users" });
    useEffect(() => {
        if (currentUser?.email && currentUser?.name && currentUser?.phone) {
            setValue("users", [
                { email: currentUser?.email, keyVal: "email", label: "Email Address:", type: "email" },
                { name: currentUser?.name, keyVal: "name", label: "Full Name:", type: "text" },
                { phone: currentUser?.phone, keyVal: "phone", label: "Phone Number:", type: "number" },
                { password: "password", keyVal: "password", label: "Password:", type: "password" },
                { password_confirmation: "password", keyVal: "password_confirmation", label: "Confirm password", type: "password" },
            ])
        }
    }, [setValue, currentUser])
    const onSubmit = useCallback(async (data) => {
        const usersData = {};
        data.users.forEach(({ keyVal }, index) => {
            if (keyVal === "password" || keyVal === "password_confirmation") {
                if (data.users[index][keyVal] !== "password") {
                    usersData[keyVal] = data.users[index][keyVal]
                }
            } else {
                if (data.users[index][keyVal] !== currentUser?.[keyVal]) {
                    usersData[keyVal] = data.users[index][keyVal]
                }
            }
        })
        if (userId) {
            const payloadOut = await dispatch(editUserByIdReq({ id: userId, ...usersData }));
            if (payloadOut.error?.message !== "Rejected") {
                setValue("users", [])
                router.push("/")
            }
        } else {
            const payloadOut = await dispatch(editMyProfileDataReq(usersData));
            if (payloadOut.error?.message !== "Rejected") {
                setValue("users", [])
                router.push("/")
            }
        }

    }, [currentUser, dispatch, router, setValue, userId])
    return (
        <Container>
            <Stack  direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4">Users</Typography>
            </Stack>
            <Card sx={{ pt: "20px", pb: "20px", pr: "20px" }}>
                <TableContainer sx={{ overflow: 'unset' }}>
                    <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
                        <ListItemButton
                            sx={{
                                minHeight: 44,
                                borderRadius: 0.75,
                                typography: 'body2',
                                textTransform: 'capitalize',
                                color: 'primary.main',
                                fontWeight: 'fontWeightSemiBold',
                                p: "20px 0"
                            }}
                            divider
                        >
                            <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
                                <PersonIcon />
                            </Box>
                            <Box component="span">personal info</Box>
                        </ListItemButton>
                    </Stack>
                    <Box pt="20px" display="flex" justifyContent="center">
                        <Typography width="630px" variant="h4" sx={{mt: 2, mb: 5}}>Personal Information</Typography>
                    </Box>
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        as="form"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Stack width="630px" spacing={3}>
                            {fields.map(({ keyVal, label, type }, index) => (
                                <Controller
                                    key={index}
                                    control={control}
                                    name={`users.${index}.${keyVal}`}
                                    render={({field: {onChange, onBlur, value, ref}}) => (
                                        <TextField
                                            name={keyVal}
                                            label={label}
                                            type={type}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            disabled={keyVal === "email"}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            ))}
                            <Box display="flex" justifyContent="flex-end">
                                <LoadingButton
                                    size="small"
                                    type="submit"
                                    variant="contained"
                                    color="inherit"
                                    sx={{
                                        ml: "10px",
                                        bgcolor: "black.100",
                                        color: "common.white",
                                        width: "80px",
                                        height: "46px",
                                    }}
                                >
                                    Save
                                </LoadingButton>
                            </Box>
                        </Stack>
                    </Stack>
                </TableContainer>
            </Card>
        </Container>
    );
}
