import CustomTaggable from "./CustomTaggable";
import React, {useCallback, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {debounce} from "@mui/material";
import Button from "@mui/material/Button";
import RemoveIcon from "@mui/icons-material/Remove";
import CustomSelect from "./CustomSelect";
import {useDispatch} from "react-redux";
import CustomAnswerList from "./CustomAnswerList";
import {requestMemberComponentsSync} from "../../usefuls/helpers";
import {useParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";

const CustomDialog = (
    {
        members = [],
        sectionId,
        componentIndex,
        componentDialog,
        answerList,
        answerMemberId,
        answerId,
        going_next,
        staticSection,
        ...customProps
    }
) => {
    const dispatch = useDispatch();
    const {matterId} = useParams();
    const [initialDefaultSet, setInitialDefaultSet] = useState(false)
    const {
        control,
        setValue,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    const addDialogItem = useCallback(() => {
        const updatedData = [...answerList];
        updatedData[componentIndex].dialog.dialogValues.push({whoSad: "", whatSad: ""})
        requestMemberComponentsSync(
            {
                customProps: {sectionId, ...customProps},
                matterId,
                updatedData,
                answerId: !!customProps.memberId ? answerMemberId === customProps.memberId ? answerId : null : answerId,
                dispatch,
                listItemHandle: true,
                going_next,
                staticSection
            }
        )
    }, [
        answerId,
        answerList,
        answerMemberId,
        componentIndex,
        customProps,
        dispatch,
        going_next,
        matterId,
        sectionId,
        staticSection
    ])

    useEffect(() => {
        if (!initialDefaultSet && componentDialog.dialogValues) {
            setValue("dialog", componentDialog.dialogValues)
            setInitialDefaultSet(true);
        }
    }, [componentDialog.dialogValues, setValue, initialDefaultSet]);

    const removeDialogItem = useCallback((index) => {
        const updatedData = [...answerList];
        updatedData[componentIndex].dialog.dialogValues =
            updatedData[componentIndex].dialog.dialogValues
                .filter((_, filteredIndex) => index !== filteredIndex)
        requestMemberComponentsSync(
            {
                customProps: {sectionId, ...customProps},
                matterId,
                updatedData,
                answerId: !!customProps.memberId ? answerMemberId === customProps.memberId ? answerId : null : answerId,
                dispatch,
                listItemHandle: true,
                going_next,
                staticSection
            }
        )
    }, [
        answerId,
        answerList,
        answerMemberId,
        componentIndex,
        customProps,
        dispatch,
        going_next,
        matterId,
        sectionId,
        staticSection
    ])
    const debouncedOnChange = debounce(({target: {id: whatSadId, value: whatSadVal}}) => {
        const updatedData = [...answerList];
        updatedData[componentIndex].dialog.dialogValues[+whatSadId].whatSad = whatSadVal
        requestMemberComponentsSync(
            {
                customProps: {sectionId, ...customProps},
                matterId,
                updatedData,
                answerId: !!customProps.memberId ? answerMemberId === customProps.memberId ? answerId : null : answerId,
                dispatch,
                going_next,
                staticSection
            }
        )
    }, 500);
    const propAttributes = {
        members: componentDialog.members,
        answerList,
        componentIndex,
        customProps: {sectionId, ...customProps},
        answerId: !!customProps.memberId ? answerMemberId === customProps.memberId ? answerId : null : answerId
    }

    return (
        <Stack sx={{marginTop: "20px"}}>
            <Typography>Dialogue</Typography>
            <CustomTaggable sectionId={sectionId} forDialog {...propAttributes} staticSection={staticSection}/>
            {componentDialog.dialogValues.map((elem, index, array) => (
                <Box display="flex" alignItems="center">
                    <CustomSelect
                        {...propAttributes}
                        matterId={matterId}
                        memberIndex={index}
                        currName={elem.whoSad}
                        staticSection={staticSection}
                    />
                    <Box display="flex" alignItems="center" height="50px" width="80%">
                        <Controller
                            control={control}
                            name={`dialog.${index}.whatSad`}
                            render={({field: {onChange, value, ref, onBlur}}) => (
                                <textarea
                                    name="whatSad"
                                    id={`${index}`}
                                    style={{ marginLeft: "10px", width: "80%", height: "40px" }}
                                    cols="30"
                                    rows="10"
                                    placeholder="What was said?"
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={(e) => {
                                        onChange(e);
                                        debouncedOnChange(e)
                                    }}
                                    ref={ref}
                                    autoComplete="off"
                                />
                            )}/>
                        {array.length !== 1 && (
                            <Button onClick={() => {
                                removeDialogItem(index)
                            }} sx={{backgroundColor: "grey.300", color: "black.200", ml: "10px"}}>
                                <RemoveIcon/>
                            </Button>
                        )}
                        {array.length - 1 === index && (
                            <Button onClick={addDialogItem}
                                    sx={{backgroundColor: "info.dark", color: "common.white", ml: "10px"}}>
                                +Add
                            </Button>
                        )}
                    </Box>
                </Box>
            ))}
            <CustomAnswerList
                componentIndex={componentIndex}
                answerList={answerList}
                answerId={answerId}
                going_next={going_next}
                staticSection={staticSection}
                answerMemberId={answerMemberId}
                {...{sectionId, ...customProps}}
            />
        </Stack>
    )
};

export default CustomDialog