import Button from "@mui/material/Button";
import {deleteQuestionReq, editQuestionWithIdAction, reminderOpenAction} from "../../../store/Matters/mattersSlice";
import Box from "@mui/material/Box";
import React, {Fragment} from "react";
import CheckBoxLabels from "../CheckBoxLabels";
import EditIcon from '@mui/icons-material/Edit';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const reminderElements = (
    {
        dispatch,
        childId,
        sectionId,
        showQuestionId = null,
        has_reminders,
        going_next,
        showActId,
        answerId,
        answerContentScheme,
        memberInfo = {},
        showSpecialReminders,
        staticSection = false,
        is_user_defined,
        is_dynamic
    }
) => {
    const parentIdHandle = showQuestionId ? { parent_question_id: showQuestionId } : {};
    return {
        button: (
            <Box display="flex" justifyContent="flex-end">
                <Button
                    sx={{ backgroundColor: "grey.300", color: "black.300", p: "8px 20px", borderRadius: "30px" }}
                    onClick={() => dispatch(reminderOpenAction({
                        question_id: childId,
                        [staticSection ? "static_section_id" : "dynamic_section_id"]: sectionId,
                        ...parentIdHandle,
                        ...memberInfo
                    }))}
                >
                    +Add reminder
                </Button>
            </Box>
        ),
        accRightPosition: (
            <Box display="flex" alignItems="center" gap="10px"  position="absolute" top="5px" right="0px">
                <Box display="flex" gap="5px">
                    {!showSpecialReminders && (
                        <Fragment>
                            {Array.from(new Set(has_reminders.type_1)).find(id => id === memberInfo.member_id) && (
                                <Box
                                    width="12px"
                                    height="12px"
                                    borderRadius="12px"
                                    sx={{ bgcolor: `accReminder.type_1` }}
                                />
                            )}
                            {Array.from(new Set(has_reminders.type_2)).find(id => id === memberInfo.member_id) && (
                                <Box
                                    width="12px"
                                    height="12px"
                                    borderRadius="12px"
                                    sx={{ bgcolor: `accReminder.type_2` }}
                                />
                            )}
                            {Array.from(new Set(has_reminders.type_3)).find(id => id === memberInfo.member_id) && (
                                <Box
                                    width="12px"
                                    height="12px"
                                    borderRadius="12px"
                                    sx={{ bgcolor: `accReminder.type_3` }}
                                />
                            )}
                        </Fragment>
                    )}
                    {showSpecialReminders && (
                        <Fragment>
                            {!!has_reminders.type_1 && (
                                <Box
                                    width="12px"
                                    height="12px"
                                    borderRadius="12px"
                                    sx={{ bgcolor: `accReminder.type_1` }}
                                />
                            )}
                            {!!has_reminders.type_2 && (
                                <Box
                                    width="12px"
                                    height="12px"
                                    borderRadius="12px"
                                    sx={{ bgcolor: `accReminder.type_2` }}
                                />
                            )}
                            {!!has_reminders.type_3 && (
                                <Box
                                    width="12px"
                                    height="12px"
                                    borderRadius="12px"
                                    sx={{ bgcolor: `accReminder.type_3` }}
                                />
                            )}
                        </Fragment>
                    )}
                </Box>
                {!!is_user_defined && <Fragment>
                    <EditIcon
                        color="inherit"
                        cursor="pointer"
                        onClick={() => dispatch(editQuestionWithIdAction({
                            parent_question_id: showQuestionId,
                            question_id: childId,
                            dynamic_section_id: !(!!staticSection) ? sectionId : null,
                            member_id: memberInfo.member_id ?? null,
                        }))}
                    />
                        {/*<DeleteForeverIcon*/}
                        {/*    color="inherit"*/}
                        {/*    cursor="pointer"*/}
                        {/*    onClick={() => dispatch(deleteQuestionReq({*/}
                        {/*        id: childId,*/}
                        {/*        parent_question_id: showQuestionId,*/}
                        {/*        for_each_member: !(!!showSpecialReminders)*/}
                        {/*    }))}*/}
                        {/*/>*/}
                    </Fragment>
                }
                {(!!is_dynamic) && (
                    <Box>
                        <CheckBoxLabels
                            checked={going_next}
                            title=""
                            questionId={childId}
                            actId={showActId}
                            parentQuestionId={showQuestionId}
                            sectionId={sectionId}
                            answerId={answerId}
                            updatedData={answerContentScheme}
                            memberId={memberInfo.member_id}
                            answerMemberId={memberInfo.answerMemberId}
                            staticSection={staticSection}
                        />
                    </Box>
                )}
            </Box>
        )
    }
}

export default reminderElements;