import React, {useEffect, Fragment, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import { useDispatch } from "react-redux";
import {useParams} from "react-router-dom";
import CustomAnswerList from "./CustomAnswerList";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {debounce} from "@mui/material";
import {requestMemberComponentsSync} from "../../usefuls/helpers";

const CustomPhrase = (
    {
        phrase,
        componentIndex,
        answerId = null,
        answerList,
        answerMemberId,
        going_next,
        staticSection,
        ...customProps
    }
) => {

    const dispatch = useDispatch();
    const {matterId} = useParams();
    const [initialDefaultSet, setInitialDefaultSet] = useState(false)
    const {
        control,
        setValue,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    useEffect(() => {
        if (!initialDefaultSet && phrase) {
            setValue("phrase", phrase)
            setInitialDefaultSet(true);
        }
    }, [phrase, setValue, initialDefaultSet]);

    const debouncedOnChange = debounce((e) => {
        let updatedData = [...answerList];
        updatedData[componentIndex] = {phrase: {id: phrase.id, value: e.target.value}};
        requestMemberComponentsSync(
            {
                customProps,
                matterId,
                updatedData,
                answerId: !!customProps.memberId ? answerMemberId === customProps.memberId ? answerId : null : answerId,
                dispatch,
                going_next,
                staticSection
            }
        )
    }, 500);

    return (
        <Fragment>
            <Stack gap={2} mt="20px" width="90%">
                <Typography>Phrase</Typography>
                <Controller
                    control={control}
                    name={`phrase.value`}
                    render={({field: {onChange, value, ref, onBlur}}) => (
                        <textarea
                            name="phrase"
                            id={`${phrase.id}`}
                            cols="30"
                            rows="10"
                            placeholder="Look, a shooting star!"
                            value={value}
                            onBlur={onBlur}
                            onChange={(e) => {
                                onChange(e);
                                debouncedOnChange(e)
                            }}
                            ref={ref}
                            style={{fontStyle: 'italic'}}
                            autoComplete="off"
                        />
                    )}/>
            </Stack>
            <CustomAnswerList
                componentIndex={componentIndex}
                answerList={answerList}
                answerId={answerId}
                going_next={going_next}
                staticSection={staticSection}
                answerMemberId={answerMemberId}
                {...customProps}
            />
        </Fragment>
    )
};

export default CustomPhrase;