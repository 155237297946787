import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Modal from "@mui/material/Modal";
import React from "react";
import { goToSecondStepReq } from "../../store/Matters/mattersSlice";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {useRouter} from "../../usefuls/hooks";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    maxHeight: "700px",
    overflowY: "auto"
};

const GenerateModal  = (
    {
        generateOpened,
        setGenerateOpened,
        setGenerateLoaderIsActive
    }
) => {
    const dispatch = useDispatch();
    const { matterId } = useParams();
    const router = useRouter();
    const handleChangeStep = (step) => {
        router.queryPush({ step })
    }
    const confirmGenerateClick = async () => {
        const { payload } = await dispatch(goToSecondStepReq(matterId));
        if (payload?.status === "success") {
            setGenerateLoaderIsActive(false)
            handleChangeStep(2);
        }
    }
    return (
        <Modal
            open={generateOpened}
            onClose={() => setGenerateOpened(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{ sx: { bgcolor: 'rgba(0, 0, 0, 0.3)' } }}
        >
            <Box sx={style}>
                <Stack gap={5} >
                    <Typography sx={{ display: "flex", gap: "5px" }}>
                        Are you sure you want to generate the statement?
                    </Typography>
                    <Box display="flex" justifyContent="flex-end">
                        <LoadingButton
                            sx={{
                                backgroundColor: "error.dark",
                                color: "common.white",
                                p: "10px 20px",
                                borderRadius: "4px",
                                width: "max-content",
                            }}
                            onClick={() => setGenerateOpened(false)}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton
                            sx={{
                                backgroundColor: "success.dark",
                                color: "common.white",
                                p: "10px 20px",
                                borderRadius: "4px",
                                width: "max-content",
                                marginLeft: "10px"
                            }}
                            onClick={() => {
                                setGenerateLoaderIsActive(true)
                                confirmGenerateClick().then(r => r)
                                setGenerateOpened(false)
                            }}
                        >
                            Confirm
                        </LoadingButton>
                    </Box>
                </Stack>
            </Box>
        </Modal>
    )
}

export default GenerateModal