import React, {useState} from "react"
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {useDispatch, useSelector} from "react-redux";
import {AIModalOpenAction} from "../../store/Matters/mattersSlice";
import Typography from "@mui/material/Typography";
import {TextField} from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {requestMemberComponentsSync} from "../../usefuls/helpers";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'white',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
};
const AIModal = _ => {
    const [aiValue, setAiValue] = useState(2);
    const { show, ...body } = useSelector(state => state.matters.openedAIModal) || {}
    const dispatch = useDispatch();
    const handleChange = e => setAiValue(e.target.value)
    const handleClose = _ => dispatch(AIModalOpenAction(false))

    return (
        <Modal
            open={show}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{ sx: { bgcolor: 'rgba(0, 0, 0, 0.3)' } }}
        >
            <Box sx={style}>
                <Stack gap={5}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        * How many paragraphs do you want to generate from this answer?
                    </Typography>
                    <Stack  gap={3}>
                        <TextField defaultValue={2} type="number" onChange={handleChange} />
                        <Button
                            sx={{
                                backgroundColor: "primary.main",
                                color: "common.white",
                                p: "8px 20px",
                                borderRadius: "4px",
                            }}
                            onClick={() => {
                                requestMemberComponentsSync({
                                    ...body,
                                    ai_configs: {
                                        ai_config_1: aiValue
                                    }
                                })
                                handleClose();
                            }}
                        >
                            Submit
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    )
}

export default AIModal;