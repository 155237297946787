import DashboardLayout from "./DashboardLayout";
import Box from "@mui/material/Box";

const RootLayout = ({ children, useContainer }) => {
    const containerStyles = {
        true: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
        },
        false: {}
    }
    return (
        <DashboardLayout>
            <Box sx={containerStyles[!!useContainer]}>
                {children}
            </Box>
        </DashboardLayout>
    )
}

export default RootLayout;