import axios from "axios";

export const axiosClient = axios.create({ baseURL: process.env.REACT_APP_API_URL });

// axiosClient.interceptors.request.use((config) => {
//         const { auth: { userInfo: { access_token: token } = {} } } = store.getState();
//         if (token) {
//             config.headers.Authorization = `Bearer ${token}`;
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );
