import CustomAccordion from "../../GlobalComponents/CustomAccordion";
import React, {Fragment, useState} from "react";
import Stack from "@mui/material/Stack";
import SpecialQuestions from "../DynamicSections/SpecialQuestions";
import Box from "@mui/material/Box";


const StaticSections = ({ special_questions = [], id, title, staticSection, has_reminders }) => {
    const [selectActId, setSelectActId] = useState(null);
    const handleAccordionChange = (event, isExpanded) => {
        if (isExpanded) {
            setSelectActId(id)
        } else {
            setSelectActId(null)
        }
    };
    return (
        <div style={{ position: "relative" }}>
            <CustomAccordion
                title={title}
                panelId={String(id)}
                detailsSX={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
                borderSX={{ border: "1px solid #E3E6EF" }}
                onChange={handleAccordionChange}
                expanded={id !== selectActId ? {expanded: false} : {expanded: true}}
            >
                {selectActId === id ? (
                    <Stack gap={1} width="100%">
                        <SpecialQuestions staticSection={staticSection} specialQuestions={special_questions} sectionId={id} />
                    </Stack>
                ) : null}
            </CustomAccordion>
            <Box display="flex" alignItems="center" gap="10px"  position="absolute" top="20px" right="15px">
                <Box display="flex" gap="5px">
                    <Fragment>
                        {!!has_reminders.type_1 && (
                            <Box
                                width="12px"
                                height="12px"
                                borderRadius="12px"
                                sx={{ bgcolor: `accReminder.type_1` }}
                            />
                        )}
                        {!!has_reminders.type_2 && (
                            <Box
                                width="12px"
                                height="12px"
                                borderRadius="12px"
                                sx={{ bgcolor: `accReminder.type_2` }}
                            />
                        )}
                        {!!has_reminders.type_3 && (
                            <Box
                                width="12px"
                                height="12px"
                                borderRadius="12px"
                                sx={{ bgcolor: `accReminder.type_3` }}
                            />
                        )}
                    </Fragment>
                </Box>
            </Box>
        </div>

    )
}

export default StaticSections;
