import {Fragment, useState} from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {useRouter} from "../../usefuls/hooks";
import {useDispatch, useSelector} from "react-redux";
import {clearUserInfoAction, logOutReq} from "../../store/Auth/authSlice";

export default function AccountPopover() {
    const [open, setOpen] = useState(null);
    const router = useRouter();
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.auth.userInfo)

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = (home, profile) => {
        if (home) {
            router.push("/")
        } else if (profile) {
            router.push("/edit-user")
        }
        setOpen(null);
    };
    const logOut = () => {
        dispatch(logOutReq())
        localStorage.removeItem("userInfo")
        dispatch(clearUserInfoAction());
        router.push("/signin")
    }

    return (
        <Fragment>
            <IconButton
                onClick={handleOpen}
                sx={{
                    width: 40,
                    height: 40,
                    background: (theme) => alpha(theme.palette.grey[500], 0.08),
                    ...(open && {
                        background: (theme) =>
                            `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                    }),
                }}
            >
                <AccountCircleIcon width="40px" height="40px" color="primary" />
            </IconButton>
            <Popover
                open={!!open}
                anchorEl={open}
                onClose={() => handleClose()}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1,
                        ml: 0.75,
                        width: 200,
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2 }}>
                    <Typography variant="subtitle2" noWrap>
                        {userInfo.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {userInfo.email}
                    </Typography>
                </Box>
                <Divider sx={{ borderStyle: 'dashed' }} />
                <MenuItem key="Home" onClick={() => handleClose(true)}>Home</MenuItem>
                <MenuItem key="Profile" onClick={() => handleClose(false, true)}>Profile</MenuItem>
                <Divider sx={{ borderStyle: 'dashed', m: 0 }} />
                <MenuItem
                    disableRipple
                    disableTouchRipple
                    onClick={() => {
                        handleClose(false, false);
                        logOut();
                    }}
                    sx={{ typography: 'body2', color: 'error.main', py: 1.5 }}
                >
                    Logout
                </MenuItem>
            </Popover>
        </Fragment>
    );
}
