import Box from "@mui/material/Box";
import {debounce} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import {useParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import CustomAnswerList from "./CustomAnswerList";
import {requestMemberComponentsSync} from "../../usefuls/helpers";

const CustomAnnexure = (
    {
        annexure,
        componentIndex,
        answerId = null,
        answerMemberId,
        answerList,
        going_next,
        staticSection,
        ...customProps
    }
) => {
    const dispatch = useDispatch();
    const {matterId} = useParams();
    const [initialDefaultSet, setInitialDefaultSet] = useState(false)
    const {
        control,
        setValue,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    useEffect(() => {
        if (!initialDefaultSet && annexure) {
            setValue("annexure", annexure)
            setInitialDefaultSet(true);
        }
    }, [annexure, setValue, initialDefaultSet]);

    const debouncedOnChange = debounce(({target: {name: annexureName, value: annexureVal}}) => {
        const updatedList = {...annexure};
        updatedList.value[annexureName] = annexureVal;
        let updatedData = [...answerList];
        updatedData[componentIndex] = {annexure: updatedList};
        requestMemberComponentsSync(
            {
                customProps,
                matterId,
                updatedData,
                answerId: !!customProps.memberId ? answerMemberId === customProps.memberId ? answerId : null : answerId,
                dispatch,
                going_next,
                staticSection
            }
        )
    }, 500);

    return (
        <Stack gap={2} mt="20px">
            <Typography>Annexure</Typography>
            <Box display="flex" alignItems="center">
                <Controller
                    control={control}
                    name={`annexure.value.lettersInpVal`}
                    render={({field: {onChange, value, ref, onBlur}}) => (
                        <textarea
                            name="lettersInpVal"
                            style={{ fontStyle: "italic", width: "160px", height: "40px" }}
                            cols="30"
                            rows="10"
                            placeholder="A,B...Z"
                            value={value}
                            onBlur={onBlur}
                            onChange={(e) => {
                                onChange(e);
                                debouncedOnChange(e)
                            }}
                            ref={ref}
                            autoComplete="off"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={`annexure.value.titleVal`}
                    render={({field: {onChange, value, ref, onBlur}}) => (
                        <textarea
                            name="titleVal"
                            style={{ marginLeft: "10px", width: "80%", height: "40px" }}
                            cols="30"
                            rows="10"
                            placeholder="Title"
                            value={value}
                            onBlur={onBlur}
                            onChange={(e) => {
                                onChange(e);
                                debouncedOnChange(e)
                            }}
                            ref={ref}
                            autoComplete="off"
                        />
                    )}
                />
            </Box>
            <CustomAnswerList
                componentIndex={componentIndex}
                answerList={answerList}
                answerId={answerId}
                going_next={going_next}
                staticSection={staticSection}
                answerMemberId={answerMemberId}
                {...customProps}
            />
        </Stack>
    )
};

export default CustomAnnexure;