import React, {Fragment} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {Tooltip} from "@mui/material";
import QuestionNoteAlert from "./QuestionNoteAlert";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {addParagraphPendingAction, addQuestionReq} from "../../store/Matters/mattersSlice";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import NewQuestionFields from "../Matters/DynamicSections/NewQuestionFields";

const CustomAccordion = (
    {
        title,
        heading,
        panelId,
        children,
        detailsSX = {},
        borderSX = {},
        onChange,
        expanded,
        toolTipDesc,
        reminders = [],
        componentInfo,
        position = "",
        showQuestionBtn = false,
        memberID = null,
        specialQuestion,
        actId,
        isChildQuestion,
        showNotCompletedQuestion
    }
) => {
    const dispatch = useDispatch();
    const addParagraphPending = useSelector(state => state.matters.addParagraphPending)
    const { matterId } = useParams();
    const { parent_question_id: forEditParentId = null } = componentInfo || {};
    const addQuestionBody = {
        position: Number(position.split(". ")[0]) + 1,
        dynamic_section_id: componentInfo?.dynamic_section_id,
        is_dynamic: true,
        matter_id: +matterId,
        act_id: actId,
        for_each_member: !(!!specialQuestion),
        ...(!!forEditParentId ? { parent_question_id: forEditParentId } : {}),
        after_question_id: panelId,
        is_child_question: isChildQuestion,
    }
    const {
        member_id: editMember_id = null,
        parent_question_id: editParentQuestionId = null,
        dynamic_section_id: editDynamicSectionId = null,
        question_id: editQuestionId = null
    } = useSelector(state => state.matters.editQuestionInfo) || {}

    const bodyQuestionFields = {
        editMember_id,
        memberID,
        editParentQuestionId,
        forEditParentId,
        editDynamicSectionId,
        dynamic_section_id: componentInfo?.dynamic_section_id,
        question_id: componentInfo?.question_id,
        editQuestionId
    }

    const updatedTitle = showNotCompletedQuestion && title
        ? `<div style="display: inline">
            ${title}
            <span style="color: red; margin-left: 10px; font-size: 20px">
                *
            </span>
        </div>` : title

    const handleAddParagraph = async () => {
        const { payload } = await dispatch(addQuestionReq({
            title: "Added paragraph",
            description: "",
            is_user_defined: 1,
            ...addQuestionBody
        }))
        if (payload.status === "success") {
            setTimeout(() => {
                dispatch(addParagraphPendingAction(false))
            }, 1000)
        }
    }

    return (
        <Accordion sx={borderSX} {...expanded} onChange={onChange ? (event, expanded) => onChange(event, expanded, panelId) : () => {}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${panelId}-content`}
                id={`panel${panelId}-header`}
                sx={{ flexDirection: 'row-reverse' }}
            >
                <Box pr="150px" display="flex" alignItems="center" width="100%">
                    {position}
                    {!!title && !(
                        editMember_id === memberID
                        && editParentQuestionId === forEditParentId
                        && editDynamicSectionId === componentInfo?.dynamic_section_id
                        && editQuestionId === componentInfo?.question_id
                    ) ? (
                        <Box sx={{ display: "inline" }} dangerouslySetInnerHTML={{__html: updatedTitle}} />
                    ) : (
                        <NewQuestionFields {...addQuestionBody} questionId={panelId} defaultValues={{
                            title: title ?? "",
                            description: toolTipDesc ?? "",
                            heading: heading ?? ""
                        }} bodyQuestionFields={bodyQuestionFields} />
                    )}
                    {toolTipDesc && (
                        <Tooltip title={toolTipDesc}>
                            <InfoRoundedIcon sx={{ ml: "2px", position: "relative", top: "5px", color: "grey.300", display: "inline-block" }}/>
                        </Tooltip>
                    )}
                </Box>
            </AccordionSummary>
            <Divider sx={{ marginTop: "10px" }} />
            <AccordionDetails sx={detailsSX}>
                {children}
                {reminders?.length > 0 && (specialQuestion || reminders?.some(({ member_id }) => member_id === memberID)) && (
                    <Fragment>
                        <Divider sx={{ mt: "15px", mb: "15px" }}/>
                        <Typography mb="10px">Note List</Typography>
                        <Box display="flex" gap="20px" flexWrap="wrap">
                            {reminders.map((elem, index) => {
                                if (elem.member_id === memberID || specialQuestion) {
                                    return <QuestionNoteAlert key={index} {...elem} componentInfo={componentInfo} />
                                }
                                return null
                            })}
                        </Box>
                    </Fragment>
                )}
                {showQuestionBtn && (
                    <Fragment>
                        <Divider sx={{ mt: "15px", mb: "15px" }}/>
                        <Box display="flex" justifyContent="center">
                            <Button sx={{ backgroundColor: "grey.300", color: "black.300", p: "8px 20px", borderRadius: "4px" }}
                                onClick={handleAddParagraph}
                                disabled={addParagraphPending}
                            >
                                +Add Paragraph
                            </Button>
                        </Box>
                    </Fragment>
                )}
            </AccordionDetails>
        </Accordion>
    );
}

export default CustomAccordion;