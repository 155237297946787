import React, {useCallback} from "react";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {useDispatch} from "react-redux";
import {reminderDeleteReq, reminderModalInfoAction, reminderOpenAction} from "../../store/Matters/mattersSlice";
import {useParams} from "react-router-dom";


const QuestionNoteAlert = (props) => {
    const dispatch = useDispatch();
    const { matterId } = useParams();

    const handleDeleteReminder = useCallback(() => {
        const { componentInfo, ...reminderElems } = props
        dispatch(reminderDeleteReq({ matterId, id: props.id, reminder: reminderElems }))
    }, [dispatch, matterId, props])

    const handleEditModalOpenReminder = useCallback(() => {
        dispatch(reminderOpenAction({ reminderId: props.id, ...props.componentInfo }))
        const { componentInfo, ...reminderInfo } = props
        dispatch(reminderModalInfoAction(reminderInfo))
    }, [dispatch, props])

    return (
        <Box
            minHeight="130px"
            minWidth="300px"
            p="20px"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{ bgcolor: `alertNote.${props.type}`, maxWidth: "350px", borderRadius: "10px" }}
        >
            <Typography color="grey.600">{props.content}</Typography>
            <Box display="flex" gap="10px" justifyContent="flex-end">
                <DeleteSweepIcon onClick={handleDeleteReminder} color="action" cursor="pointer" />
                <BorderColorIcon onClick={handleEditModalOpenReminder} color="action" cursor="pointer"/>
            </Box>
        </Box>
    )
}

export default QuestionNoteAlert;
