import {bgGradient} from "../../theme/css";
import React, {useCallback, useState} from "react";
import {useRouter} from "../../usefuls/hooks";
import {alpha, useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {Card, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import LoadingButton from '@mui/lab/LoadingButton';
import Iconify from "../ui-kit/Iconify";
import Link from '@mui/material/Link';
import {Link as ReactLink} from "react-router-dom"
import {Controller, useForm} from "react-hook-form";
import { loginUserReq } from "../../store/Auth/authSlice";
import { useDispatch } from "react-redux";

const SignIn = () => {
    const theme = useTheme();
    const router = useRouter();
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const { handleSubmit, control } = useForm({
        defaultValues: {
            email: "",
            password: ""
        },
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    const onSubmit = useCallback(async (data) => {
        const { payload } = await dispatch(loginUserReq(data));
        if (payload?.access_token) {
            router.push('/')
        }
    }, [dispatch, router])

    return (
        <Box
            sx={{
                ...bgGradient({
                    color: alpha(theme.palette.background.default, 0.9),
                    imgUrl: '/assets/background/overlay_4.jpg',
                }),
                height: 1,
            }}
        >
            <img
                src="/images/Karni.svg" alt="Karni SVG"
                style={{width: "52px", height: "40px", display: 'inline-flex', margin: "20px"}}
            />
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{height: "80vh"}}
                as="form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Card sx={{ p: 5, width: 1, maxWidth: 420 }}>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h4" sx={{mt: 2, mb: 5}}>Sign in to Admin</Typography>
                    </Box>
                    <Stack spacing={3}>
                        <Controller
                            control={control}
                            name="email"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TextField
                                    name="email"
                                    label="Email address"
                                    type="email"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    ref={ref}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="password"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TextField
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    ref={ref}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{my: 3}}>
                        <Link variant="subtitle2" underline="hover" >
                            <ReactLink to="/forgot">Forgot password?</ReactLink>
                        </Link>
                    </Stack>
                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="inherit"
                    >
                        Login
                    </LoadingButton>

                </Card>
            </Stack>
        </Box>
    )
}

export default SignIn;