import { useEffect } from 'react';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Iconify from "../ui-kit/Iconify";
import UserTableRow from "../Layout/UserTableRow";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {useDispatch, useSelector} from "react-redux";
import {usersListReq} from "../../store/Users/usersSlice";
import {useRouter} from "../../usefuls/hooks";
import {useSearchParams} from "react-router-dom";
export default function UserPage() {
    const [searchParams] = useSearchParams();
    const userInfo = useSelector(state => state.auth.userInfo)
    const {
        data: usersList,
        total: listLength,
        current_page: currentPage,
        per_page: rowsPerPage
    } = useSelector(state => state.users.usersList) || {}
    const dispatch = useDispatch();
    const router = useRouter();
    useEffect(() => {
        let queryParams = ""
        if (!!searchParams.size && searchParams.get("page") && searchParams.get("page") !== "1") {
            queryParams = `?page=${searchParams.get("page")}`
        }
        dispatch(usersListReq(queryParams));
    }, [dispatch, searchParams]);

    const handleChangePage = (event, newPage) => {
        router.queryPush(newPage !== 0 ? { page: newPage + 1 } : {})
    };

    return (
        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4">Users</Typography>
                {userInfo.role === "admin" && (
                    <Button
                        onClick={() => router.push("/new-user")}
                        variant="contained"
                        color="inherit"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                        New User
                    </Button>
                )}
            </Stack>
            <Card sx={{ padding: "20px" }}>
                <TableContainer sx={{ overflow: 'unset' }}>
                    <Table sx={{ minWidth: 800 }}>
                        <TableHead>
                            <TableRow>
                                {[
                                    { id: 'name', label: 'Name' },
                                    { id: 'email', label: 'Email' },
                                    { id: 'phoneNumber', label: 'Phone number' },
                                    { id: 'date', label: 'Date' },
                                    { id: '' },
                                ].map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.align || 'left'}
                                        sx={{ width: headCell.width, minWidth: headCell.minWidth }}
                                    >
                                        {headCell.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ pl: "20px" }}>
                            {usersList?.map((row) => (
                                <UserTableRow
                                    key={row.id}
                                    name={row.name}
                                    email={row.email}
                                    date={row.created_at}
                                    phoneNumber={row.phone}
                                    role={userInfo.role}
                                    id={row.id}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    page={currentPage ? currentPage - 1 : 0}
                    component="div"
                    count={listLength || 0}
                    rowsPerPage={rowsPerPage || 8}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[]}
                />
            </Card>
        </Container>
    );
}
