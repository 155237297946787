import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from "@mui/material/Typography";

const steps = [
    'Interview Stage',
    // 'Draft Stage',
    'Final Statement',
];

const LabelStepper = () => {
    return (
        <Box sx={{ width: '100%', mt: "70px" }}>
            <Stepper activeStep={+window.location.search.split("?step=")[1] - 1} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel><Typography>{label}</Typography></StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}

export default LabelStepper