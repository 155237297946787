import React, {Fragment, useState} from "react";
import Stack from "@mui/material/Stack";
import CustomAccordion from "../../GlobalComponents/CustomAccordion";
import { contentSectionHandleAction } from "../../../store/Matters/mattersSlice";
import { useDispatch, useSelector } from "react-redux";
import AnswerContent from "./AnswerContent";
import ShowYesNoBtn from "./ShowYesNoBtn";
import reminderElements from "./ReminderElements";
import ReminderModal from "../../GlobalComponents/ReminderModal";

const SpecialQuestions = ({ specialQuestions, sectionId, staticSection = false, actId }) => {
    const [selectActId, setSelectActId] = useState(null);
    const [selectChildActId, setSelectChildActId] = useState(null);
    const dispatch = useDispatch();
    const { open: reminderOpened } = useSelector(state => state.matters.reminderOpened);
    const matterLatest = useSelector(state => state.matters.matterLatest) || {};
    const handleAnswerUpdate = (answer) => {
        dispatch(contentSectionHandleAction(answer[0] ? [{
            ...answer[0],
            content: JSON.parse(answer[0]?.content)
        }] : [{content: {list: [{ note: { id: Math.random(), value: "" }}]}}]))}

    return <Fragment>
        {specialQuestions.map((
            {
                description: parentDesc,
                id,
                questions: specialChildQuestion = [],
                title,
                answer,
                has_reminders,
                is_dynamic: isParentDynamic,
                reminders: remindersParent,
                position: parentPosition,
                is_user_defined: is_user_defined_parent,
                has_yes_no
            }) => {
            const [{
                id: answerIdParent = null,
                content: stringifySchemeParent,
                going_next: goingNextParent = 1,
                heading: answerHeadingParent
            } = {}] = answer || []
            const answerContentSchemeParent = stringifySchemeParent
                ? JSON.parse(stringifySchemeParent).list
                : [{note: {id: Math.random(), value: "", ai_config: false}}];

            const customParentProps = {
                answerId: answerIdParent,
                answerList: answerContentSchemeParent,
                questionId: id,
                actId: sectionId,
                going_next: goingNextParent,
                sectionId,
                staticSection: staticSection,
                is_dynamic: isParentDynamic,
                questionData: {
                    position: parentPosition,
                    lastPosition: specialQuestions[specialQuestions.length - 1].position,
                    lastQuestionId: specialQuestions[specialQuestions.length - 1].id,
                    componentInfo: {
                        question_id: id,
                        [staticSection ? "static_section_id" : "dynamic_section_id"]: sectionId,
                    },
                    actId,
                    specialQuestion: true,
                }
            }
            const reminderAttributesParent = {
                dispatch,
                childId: id,
                sectionId,
                has_reminders: has_reminders,
                going_next: goingNextParent,
                showActId: sectionId,
                answerId: answerIdParent,
                answerContentScheme: answerContentSchemeParent,
                showSpecialReminders: true,
                staticSection,
                is_user_defined: is_user_defined_parent,
                is_dynamic: isParentDynamic
            }
            const handleAccordionChange = (event, isExpanded, panelId) => {
                if (isExpanded) {
                    setTimeout(() => {
                        event.target.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                    }, 0)
                    setSelectActId(panelId)
                } else {
                    setSelectActId(null)
                }
            };
            const showNotCompletedQuestion = specialChildQuestion.length > 0
                ? specialChildQuestion.some(elem => elem.answer.length === 0 || !elem.answer)
                : !answer || answer.length === 0


            return <div key={id} style={{position: "relative"}}>
                <CustomAccordion
                    title={title}
                    heading={answerHeadingParent}
                    panelId={id}
                    borderSX={{
                        border: "1px solid #E3E6EF",
                        ...(matterLatest.id === id ? {backgroundColor: "primary.lighter"} : {})
                    }}
                    detailsSX={{backgroundColor: "common.white"}}
                    onChange={handleAccordionChange}
                    expanded={id !== selectActId ? {expanded: false} : {expanded: true}}
                    toolTipDesc={parentDesc}
                    reminders={remindersParent}
                    componentInfo={{
                        question_id: id,
                        [staticSection ? "static_section_id" : "dynamic_section_id"]: sectionId,
                    }}
                    position={`${parentPosition}. `}
                    showQuestionBtn={!(!!staticSection)}
                    specialQuestion
                    actId={actId}
                    isChildQuestion={false}
                    showNotCompletedQuestion={showNotCompletedQuestion}
                >
                    {selectActId === id ? (
                        <Fragment>
                            <ShowYesNoBtn
                                customProps={customParentProps}
                                staticSection={staticSection}
                                going_next={goingNextParent}
                                answer={answer}
                                has_yes_no={has_yes_no}
                            >
                                {specialChildQuestion.length > 0 ? (
                                    <Fragment>

                                            <Stack>
                                                {specialChildQuestion.map((
                                                    {
                                                        id: childId,
                                                        description,
                                                        answer,
                                                        title,
                                                        reminders,
                                                        is_dynamic: isChildDynamic,
                                                        has_reminders: hasRemindersParent,
                                                        position,
                                                        is_user_defined,
                                                    }
                                                ) => {
                                                    const [{
                                                        id: answerId = null,
                                                        content: stringifyScheme,
                                                        going_next = 1,
                                                        heading: answerHeading
                                                    } = {}] = answer || []
                                                    const answerContentScheme = stringifyScheme
                                                        ? JSON.parse(stringifyScheme).list
                                                        : [{note: {id: Math.random(), value: "", ai_config: false}}]
                                                    const handleAccordionChange2 = (event, isExpanded, panelChildId) => {
                                                        if (isExpanded) {
                                                            setTimeout(() => {
                                                                event.target.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                                                            }, 0)
                                                            setSelectChildActId(panelChildId)
                                                            handleAnswerUpdate(answer)
                                                        } else {
                                                            setSelectChildActId(null)
                                                        }
                                                    };
                                                    const customProps = {
                                                        answerId,
                                                        answerList: answerContentScheme,
                                                        questionId: selectChildActId,
                                                        actId: sectionId,
                                                        parentQuestionId: id,
                                                        going_next: going_next,
                                                        sectionId,
                                                        staticSection: staticSection,
                                                        is_dynamic: isChildDynamic,
                                                        questionData: {
                                                            position,
                                                            lastPosition: specialChildQuestion[specialChildQuestion.length - 1].position,
                                                            lastQuestionId: specialChildQuestion[specialChildQuestion.length - 1].id,
                                                            componentInfo: {
                                                                question_id: id,
                                                                parent_question_id: id,
                                                                [staticSection ? "static_section_id" : "dynamic_section_id"]: sectionId,
                                                            },
                                                            actId,
                                                            specialQuestion: true,
                                                        }
                                                    }
                                                    const reminderAttributes = {
                                                        dispatch,
                                                        childId,
                                                        sectionId,
                                                        showQuestionId: id,
                                                        reminders,
                                                        going_next,
                                                        showActId: sectionId,
                                                        answerId,
                                                        answerContentScheme,
                                                        has_reminders: hasRemindersParent,
                                                        showSpecialReminders: true,
                                                        staticSection,
                                                        is_user_defined,
                                                        is_dynamic: isChildDynamic,
                                                    }
                                                    return (
                                                        <div key={childId} style={{position: "relative"}}>
                                                            <CustomAccordion
                                                                title={title}
                                                                heading={answerHeading}
                                                                panelId={childId}
                                                                borderSX={{
                                                                    border: "1px solid #E3E6EF",
                                                                    backgroundColor: "grey.200"
                                                                }}
                                                                detailsSX={{backgroundColor: "common.white"}}
                                                                onChange={handleAccordionChange2}
                                                                expanded={selectChildActId !== childId ? {expanded: false} : {expanded: true}}
                                                                toolTipDesc={description}
                                                                reminders={reminders}
                                                                componentInfo={{
                                                                    question_id: childId,
                                                                    [staticSection ? "static_section_id" : "dynamic_section_id"]: sectionId,
                                                                    parent_question_id: id,
                                                                }}
                                                                position={`${position}. `}
                                                                showQuestionBtn={!(!!staticSection)}
                                                                specialQuestion
                                                                actId={actId}
                                                                isChildQuestion={true}
                                                                showNotCompletedQuestion={!answer || answer.length === 0}
                                                            >
                                                                {selectChildActId === childId && answerContentScheme.map((elem, componentIndex) => (
                                                                    <AnswerContent
                                                                        {...elem}
                                                                        heading={answerHeading}
                                                                        componentIndex={componentIndex}
                                                                        customProps={customProps}
                                                                    />
                                                                ))}
                                                                {reminderElements(reminderAttributes).button}
                                                            </CustomAccordion>
                                                            {reminderElements(reminderAttributes).accRightPosition}
                                                        </div>
                                                    );
                                                })}
                                            </Stack>
                                    </Fragment>
                                ) : <Fragment>
                                        {answerContentSchemeParent.map((elem, componentIndex) => (
                                            <AnswerContent
                                                {...elem}
                                                heading={answerHeadingParent}
                                                componentIndex={componentIndex}
                                                customProps={customParentProps}
                                            />
                                        ))}
                                        {reminderElements(reminderAttributesParent).button}
                                    </Fragment>
                                }
                            </ShowYesNoBtn>
                        </Fragment>
                    ) : null}
                </CustomAccordion>
                {reminderElements(reminderAttributesParent).accRightPosition}
            </div>;
        })}
        {reminderOpened && <ReminderModal/>}
    </Fragment>;

}

export default SpecialQuestions;
