import React, { Fragment, useCallback } from "react"
import Button from "@mui/material/Button";
import Box from "@mui/material/Box"
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { requestMemberComponentsSync } from "../../usefuls/helpers";
import AIModal from "../Matters/AIModal";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import IosSwitch from "./IosSwitch";

const sxHandler = (first) => {
    const marginLeft = !!first ? {} : { ml: "10px" }
    return {
        color: "black.200",
        border: "1px solid #C0C0C0",
        ...marginLeft
    }
}
const sectionAddFuncAttributeVal = {
    note: { note: { id: Math.random(), value: "", ai_config: false }},
    commentList: { commentList: [{ id: Math.random(), value: "" }]},
    list: { list: [{ id: Math.random(), value: "" }]},
    phrase: { phrase: { id: Math.random(), value: "" }},
    annexure: { annexure: { id: Math.random(), value: { lettersInpVal: "", titleVal: "" } }},
    dialog: { dialog: { id: Math.random(), members: [], dialogValues: [{whoSad: "0", whatSad: ""}]} }
}

const CustomAnswerList = (
    {
        componentIndex = 0,
        answerList,
        answerId,
        answerMemberId,
        going_next,
        staticSection,
        showAI,
        ...customProps
    }
) => {
    const dispatch = useDispatch();
    const {matterId} = useParams();
    const addSectionHandler = useCallback((value) => {
        const updatedData = [...answerList]
        updatedData.splice(componentIndex + 1 ?? 1, 0, value)
        requestMemberComponentsSync(
            {
                customProps,
                matterId,
                updatedData,
                answerId: !!customProps.memberId ? answerMemberId === customProps.memberId ? answerId : null : answerId,
                dispatch,
                going_next,
                staticSection
            }
        )
    }, [answerId, answerList, answerMemberId, componentIndex, customProps, dispatch, going_next, matterId, staticSection])
    const removeSectionHandler = useCallback(() => {
        const updatedData = [...answerList].filter((_, listIndex) => listIndex !== componentIndex)
        requestMemberComponentsSync(
            {
                customProps,
                matterId,
                updatedData,
                answerId: !!customProps.memberId ? answerMemberId === customProps.memberId ? answerId : null : answerId,
                dispatch,
                going_next,
                staticSection
            }
        )
    }, [answerId, answerList, answerMemberId, componentIndex, customProps, dispatch, going_next, matterId, staticSection])

    const AIClickHandler = useCallback((e) => {
        const updatedData = [...answerList]
        updatedData[componentIndex].note.ai_config = e.target.checked
        requestMemberComponentsSync({
            customProps,
            matterId,
            updatedData,
            answerId,
            dispatch,
            going_next,
            staticSection,
        })
    }, [answerId, answerList, componentIndex, customProps, dispatch, going_next, matterId, staticSection])

    const {
        // note: attrNote,
        commentList: attrCommentList,
        list: attrList,
        phrase: attrPhrase,
        annexure: attrAnnexure,
        dialog: attrDialog
    } = sectionAddFuncAttributeVal;

    return (
        <Box display="flex" mt="10px">
            {/* <Button onClick={() => addSectionHandler(attrNote)} sx={sxHandler(true)}>
                Note
            </Button> */}
            {answerList.length <= 1 && (
                <Fragment>
                    <Button onClick={() => addSectionHandler(attrCommentList)} sx={sxHandler(true)}>
                        Comment list
                    </Button>
                    <Button onClick={() => addSectionHandler(attrList)} sx={sxHandler()}>
                        List
                    </Button>
                    <Button onClick={() => addSectionHandler(attrDialog)} sx={sxHandler()}>
                        Dialogue
                    </Button>
                    <Button onClick={() => addSectionHandler(attrPhrase)} sx={sxHandler()}>
                        Phrase
                    </Button>
                    <Button onClick={() => addSectionHandler(attrAnnexure)} sx={sxHandler()}>
                        Annexure
                    </Button>
                </Fragment>
            )}
            {answerList.length === 2 && componentIndex !== 0 && (
                <Button onClick={() => addSectionHandler(attrAnnexure)} sx={sxHandler()}>
                    Annexure
                </Button>
            )}
            {componentIndex !== 0 && (
                <Button onClick={removeSectionHandler} sx={{color: "white", backgroundColor: "error.dark", ml: "10px"}}>
                    <DeleteSweepIcon color="inherit" cursor="pointer"/>
                </Button>
            )}
            {showAI && <IosSwitch onChange={AIClickHandler} checked={!![...answerList][componentIndex].note.ai_config}/>}
            <AIModal/>
        </Box>
    );
};

export default CustomAnswerList;