import React, {useCallback, useEffect, useState} from "react"
import {debounce} from "@mui/material";
import Box from "@mui/material/Box";
import RemoveIcon from '@mui/icons-material/Remove';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useDispatch } from "react-redux";
import {_commentListAlphabet} from "../../_mock";
import CustomAnswerList from "./CustomAnswerList";
import {Controller, useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {requestMemberComponentsSync} from "../../usefuls/helpers";


const CustomList = (
    {
        componentList,
        componentIndex,
        answerId = null,
        answerMemberId,
        answerList,
        going_next,
        staticSection,
        ...customProps
    }
) => {
    const dispatch = useDispatch();
    const [initialDefaultSet, setInitialDefaultSet] = useState(false)
    const {
        control,
        setValue,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })
    const {matterId} = useParams();

    const addListItem = useCallback(() => {
        const updatedData = [...answerList];
        updatedData[componentIndex].list.push({id: Math.random(), value: ""})
        requestMemberComponentsSync(
            {
                customProps,
                matterId,
                updatedData,
                answerId: !!customProps.memberId ? answerMemberId === customProps.memberId ? answerId : null : answerId,
                dispatch,
                listItemHandle: true,
                going_next,
                staticSection
            }
        )
    }, [
        answerId,
        answerList,
        answerMemberId,
        componentIndex,
        customProps,
        dispatch,
        going_next,
        matterId,
        staticSection
    ])
    const removeListItem = useCallback((index) => {
        const updatedData = [...answerList];
        updatedData[componentIndex].list =
            updatedData[componentIndex].list.filter((_, filteredIndex) => index !== filteredIndex)
        requestMemberComponentsSync(
            {
                customProps,
                matterId,
                updatedData,
                answerId: !!customProps.memberId ? answerMemberId === customProps.memberId ? answerId : null : answerId,
                dispatch,
                listItemHandle: true,
                going_next,
                staticSection
            }
        )
    }, [
        answerId,
        answerList,
        answerMemberId,
        componentIndex,
        customProps,
        dispatch,
        going_next,
        matterId,
        staticSection
    ])

    useEffect(() => {
        if (!initialDefaultSet && componentList) {
            setValue("list", componentList)
            setInitialDefaultSet(true);
        }
    }, [componentList, setValue, initialDefaultSet]);

    const debouncedOnChange = debounce(({target: {id: listId, value: listVal}}) => {
        const updatedList = [...componentList].map(listItem => {
            if (String(listItem.id) === String(listId)) {
                return {
                    id: listItem.id,
                    value: listVal
                }
            }
            return listItem
        })
        let updatedData = [...answerList];
        updatedData[componentIndex] = {list: updatedList};
        requestMemberComponentsSync(
            {
                customProps,
                matterId,
                updatedData,
                answerId: !!customProps.memberId ? answerMemberId === customProps.memberId ? answerId : null : answerId,
                dispatch,
                going_next,
                staticSection
            }
        )
    }, 500);

    return (
        <Stack gap={2} mt="20px">
            <Typography>List</Typography>
            {componentList.map(({id: elemId}, listItemIndex) => {
                return <Box display="flex" alignItems="center" height="50px">
                    <Typography>{_commentListAlphabet[listItemIndex]}</Typography>
                    <Controller
                        control={control}
                        name={`list.${listItemIndex}.value`}
                        render={({field: {onChange, value, ref, onBlur}}) => (
                            <textarea
                                name="list"
                                id={elemId}
                                style={{ marginLeft: "10px", width: "80%", height: "40px" }}
                                cols="30"
                                rows="10"
                                placeholder="Text"
                                value={value}
                                onBlur={onBlur}
                                onChange={(e) => {
                                    onChange(e);
                                    debouncedOnChange(e)
                                }}
                                ref={ref}
                                autoComplete="off"
                            />
                        )}/>
                    {componentList.length !== 1 && (
                        <Button onClick={() => {
                            removeListItem(listItemIndex)
                        }} sx={{backgroundColor: "grey.300", color: "black.200", ml: "10px"}}>
                            <RemoveIcon/>
                        </Button>
                    )}
                    {listItemIndex === componentList.length - 1 && (
                        <Button onClick={addListItem}
                                sx={{backgroundColor: "info.dark", color: "common.white", ml: "10px"}}>
                            +Add
                        </Button>
                    )}
                </Box>
            })}
            <CustomAnswerList
                componentIndex={componentIndex}
                answerList={answerList}
                answerId={answerId}
                going_next={going_next}
                staticSection={staticSection}
                answerMemberId={answerMemberId}
                {...customProps}
            />
        </Stack>
    )
};

export default CustomList;