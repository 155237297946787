import React, {useEffect, Fragment, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import { useDispatch } from "react-redux";
import {useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {debounce} from "@mui/material";
import {requestMemberComponentsSync} from "../../usefuls/helpers";

const CustomHeading = (
    {
        heading,
        componentIndex,
        answerId = null,
        answerMemberId,
        answerList,
        going_next,
        staticSection,
        ...customProps
    }
) =>  {
    const dispatch = useDispatch();
    const { matterId } = useParams();
    const [initialDefaultSet, setInitialDefaultSet] = useState(false)
    const {
        control,
        setValue,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })
    useEffect(() => {
        if (!initialDefaultSet && heading) {
            setValue("heading", heading);
            setInitialDefaultSet(true);
        }
    }, [heading, setValue, initialDefaultSet]);

    const debouncedOnChange = debounce((e) => {
        let updatedData = [...answerList];
        requestMemberComponentsSync(
            {
                customProps,
                matterId,
                updatedData,
                answerId: !!customProps.memberId ? answerMemberId === customProps.memberId ? answerId : null : answerId,
                dispatch,
                going_next,
                staticSection,
                heading: e.target.value
            }
        )
    }, 500);

    return (
        <Fragment>
            <Stack gap={2} mt="20px" width="90%">
                <Typography>Heading</Typography>
                <Controller
                    control={control}
                    name={`heading`}
                    render={({field: {onChange, value, ref, onBlur}}) => {
                        return (
                            <textarea
                                name="heading"
                                cols="30"
                                rows="10"
                                placeholder="Type Heading"
                                value={value}
                                onBlur={onBlur}
                                onChange={(e) => {
                                    onChange(e);
                                    debouncedOnChange(e)
                                }}
                                ref={ref}
                                autoComplete="off"
                                style={{ height: "100px" }}
                            />
                        )
                    }}/>
            </Stack>
        </Fragment>
    )
}

export default CustomHeading;
