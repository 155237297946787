import React from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import {useParams} from "react-router-dom";
import {addMemberReq, deleteMemberReq} from "../../store/Matters/mattersSlice";
import {useDispatch} from "react-redux";
import {requestMemberComponentsSync} from "../../usefuls/helpers";

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const CustomTaggable = (
    {
        members,
        sectionId,
        forDialog,
        answerList,
        componentIndex,
        customProps,
        answerId,
        staticSection
    }
) => {
    const { matterId } = useParams();
    const dispatch = useDispatch()

    const handleDelete = i => {
        if (!forDialog) {
            const {id: memberId} = members[i]
            dispatch(deleteMemberReq({ memberId, sectionId }))
        } else {
            const updatedData = [...answerList];
            updatedData[componentIndex].dialog.members =
                updatedData[componentIndex].dialog.members.filter((_, filteredIndex) => i !== filteredIndex)
            requestMemberComponentsSync(
                {
                    customProps,
                    matterId,
                    updatedData,
                    answerId,
                    dispatch,
                    listItemHandle: true,
                    staticSection
                }
            )
        }
    };

    const handleAddition = tag => {
        if (!forDialog) {
            dispatch(addMemberReq({
                dynamic_section_id: sectionId,
                matter_id: +matterId,
                name: tag.text
            }))
        } else {
            const updatedData = [...answerList];
            updatedData[componentIndex].dialog.members.push({ id: Math.random(), name: tag.text })
            requestMemberComponentsSync(
                {
                    customProps,
                    matterId,
                    updatedData,
                    answerId,
                    dispatch,
                    listItemHandle: true,
                    staticSection
                }
            )
        }
    };

    const handleTagClick = index => {
        console.log('The tag at index ' + index + ' was clicked');
    };

    return (
        <div className="react-tags">
            <div>
                <ReactTags
                    tags={members.map(({ name }) => {
                        return { id: name, text: name }
                    })}
                    delimiters={delimiters}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    handleTagClick={handleTagClick}
                    inputFieldPosition="bottom"
                    allowDragDrop={false}
                    autocomplete
                    inline
                />
            </div>
        </div>
    );
};

export default CustomTaggable;
