import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import ThemeProvider from "./theme";
import SignIn from "./components/LogIn/SignIn";
import UserPage from "./components/Employees";
import { useSelector } from 'react-redux'
import ForgotPassword from "./components/LogIn/ForgotPassword";
import AlertToastLayout from "./components/ui-kit/AlertToast";
import ResetPassword from "./components/LogIn/ResetPassword";
import {Fragment} from "react";
import NewEmployee from "./components/Employees/NewEmployee";
import EditEmployee from "./components/Employees/EditEmployee";
import MattersList from "./components/Matters";
import RootLayout from "./components/Layout/RootLayout";
import EditMatter from "./components/Matters/EditMatter";

function App() {
    const userInfo = useSelector(state => state.auth.userInfo)
    const userLogged = !!(localStorage.getItem("userInfo") || userInfo);
    console.warn = console.error = () => {};
    return (
        <ThemeProvider>
            <AlertToastLayout>
                <BrowserRouter>
                    <Routes>
                        {userLogged ? (
                            <Fragment>
                                <Route path="/" element={<RootLayout useContainer ><UserPage /></RootLayout>}/>
                                <Route path="/new-user" element={<RootLayout useContainer ><NewEmployee /></RootLayout>}/>
                                <Route path="/edit-user/:id" element={<RootLayout useContainer ><EditEmployee /></RootLayout>}/>
                                <Route path="/edit-user" element={<RootLayout useContainer ><EditEmployee /></RootLayout>}/>
                                <Route path="/matters" element={<RootLayout useContainer ><MattersList /></RootLayout>}/>
                                <Route path="/matter/:matterId" element={<RootLayout><EditMatter /></RootLayout>}/>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Route path="signin" element={<SignIn />} />
                                <Route path="forgot" element={<ForgotPassword />} />
                                <Route path="reset" element={<ResetPassword />} />
                                <Route path="*" element={<Navigate to="/signin" />} />
                            </Fragment>
                        )}
                    </Routes>
                </BrowserRouter>
            </AlertToastLayout>
        </ThemeProvider>
    );
}

export default App;
