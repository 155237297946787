import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { Fragment, useState } from "react";
import CustomAccordion from "../../GlobalComponents/CustomAccordion";
import { useDispatch, useSelector } from "react-redux";
import { contentSectionHandleAction } from "../../../store/Matters/mattersSlice";
import ReminderModal from "../../GlobalComponents/ReminderModal";
import AnswerContent from "./AnswerContent";
import reminderElements from "./ReminderElements";
import ShowYesNoBtn from "./ShowYesNoBtn";


const MemberQuestions = ({ members, memberQuestions, sectionId, actId, titleAboutMember }) => {
    const [{ selectMemberId, selectActId }, setSelectId] = useState({ selectMemberId: null, selectActId: null });
    const [selectChildActId, setSelectChildActId] = useState(null);
    const { open: reminderOpened } = useSelector(state => state.matters.reminderOpened);
    const matterLatest = useSelector(state => state.matters.matterLatest) || {};

    const dispatch = useDispatch();
    const handleAnswerUpdate = (answer) => {
        dispatch(contentSectionHandleAction(answer[0] ? [{
            ...answer[0],
            content: JSON.parse(answer[0]?.content)
        }] : [{content: {list: [{ note: { id: Math.random(), value: "", ai_config: false }}]}}]))
    }

    return (
        <Fragment>
            {members.map(({name, id: memberID}) => (
                <Stack key={memberID} gap={1}>
                    <Typography sx={{ fontWeight: 'bold' }} mt="20px">
                        {titleAboutMember || "Questions About"} {name}
                    </Typography>
                    {memberQuestions.map((
                        {
                            description: parentDesc,
                            id,
                            questions: memberChildQuestion = [],
                            heading,
                            title,
                            answer,
                            reminders: remindersParent,
                            is_dynamic: isParentDynamic,
                            has_reminders,
                            position: parentPosition,
                            is_user_defined: is_user_defined_parent,
                            has_yes_no
                        }
                    ) => {
                        const {
                            id: answerIdParent = null,
                            content: stringifySchemeParent,
                            going_next: goingNextParent = 1,
                            member_id: answerParentMemberId,
                            heading: answerHeadingParent
                        } = answer.find(answerElement => answerElement.member_id === memberID) || {}
                        const answerContentSchemeParent = stringifySchemeParent && memberID === answerParentMemberId
                            ? JSON.parse(stringifySchemeParent).list
                            : [{ note: { id: Math.random(), value: "", ai_config: false } }]

                        const customParentProps = {
                            answerId: answerIdParent,
                            answerList: answerContentSchemeParent,
                            answerMemberId: answerParentMemberId,
                            questionId: id,
                            actId: sectionId,
                            going_next: goingNextParent,
                            is_dynamic: isParentDynamic,
                            memberId: memberID,
                            sectionId,
                            questionData: {
                                position: parentPosition,
                                lastPosition: memberQuestions[memberQuestions.length - 1].position,
                                lastQuestionId: memberQuestions[memberQuestions.length - 1].id,
                                componentInfo: {
                                    question_id: id,
                                    dynamic_section_id: sectionId,
                                    member_id: memberID
                                },
                                actId,
                                specialQuestion: false,
                            }
                        }
                        const reminderAttributesParent = {
                            dispatch,
                            childId: id,
                            sectionId,
                            has_reminders: has_reminders,
                            going_next: goingNextParent,
                            showActId: sectionId,
                            answerId: answerIdParent,
                            answerContentScheme: answerContentSchemeParent,
                            memberInfo: {member_id: memberID, answerMemberId: answerParentMemberId},
                            is_user_defined: is_user_defined_parent,
                            is_dynamic: isParentDynamic,
                        }
                        const handleAccordionChange = (event, isExpanded, panelId) => {
                            if (isExpanded) {
                                setTimeout(() => {
                                    event.target.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                                }, 0)
                                setSelectId({ selectMemberId: memberID, selectActId: panelId })
                            } else {
                                setSelectId({ selectMemberId: null, selectActId: null })
                            }
                        };
                        const showNotCompletedQuestion = memberChildQuestion.length > 0
                            ? memberChildQuestion.some(elem => elem.answer.length === 0 || !elem.answer)
                            : !answer || answer.length === 0
                        return <div key={id} style={{ position: "relative" }}>
                            <CustomAccordion
                                title={title}
                                heading={heading}
                                panelId={id}
                                borderSX={{
                                    border: "1px solid #E3E6EF",
                                    ...(matterLatest.id === id ? { backgroundColor: "primary.lighter" } : {})
                                }}
                                detailsSX={{backgroundColor: "common.white"}}
                                onChange={handleAccordionChange}
                                expanded={id !== selectActId || memberID !== selectMemberId ? {expanded: false} : {expanded: true}}
                                toolTipDesc={parentDesc}
                                showQuestionBtn
                                reminders={remindersParent}
                                componentInfo={{
                                    question_id: id,
                                    dynamic_section_id: sectionId,
                                    member_id: memberID
                                }}
                                position={`${parentPosition}. `}
                                memberID={memberID}
                                actId={actId}
                                isChildQuestion={false}
                                showNotCompletedQuestion={showNotCompletedQuestion}
                            >
                            {selectActId === id && selectMemberId === memberID ? (
                                <Fragment>
                                    <ShowYesNoBtn
                                        customProps={customParentProps}
                                        going_next={goingNextParent}
                                        answer={answer}
                                        has_yes_no={has_yes_no}
                                    >
                                        {memberChildQuestion.length > 0 ? (
                                            <Fragment>

                                                    <Stack>
                                                        {memberChildQuestion.map((
                                                            {
                                                                id: childId,
                                                                description,
                                                                answer: childAnswer,
                                                                heading,
                                                                is_dynamic: isChildDynamic,
                                                                title,
                                                                reminders,
                                                                has_reminders,
                                                                position,
                                                                is_user_defined,
                                                            }
                                                        ) => {
                                                            const {
                                                                id: answerId = null,
                                                                content: stringifyScheme,
                                                                going_next = 1,
                                                                member_id: answerMemberId,
                                                                heading: answerHeading
                                                            } = childAnswer.find(answerElement => answerElement.member_id === memberID) || {}
                                                            const answerContentScheme = stringifyScheme && memberID === answerMemberId
                                                                ? JSON.parse(stringifyScheme).list
                                                                : [{note: {id: Math.random(), value: "", ai_config: false}}]
                                                            const handleAccordionChange2 = (event, isExpanded, panelChildId) => {
                                                                if (isExpanded) {
                                                                    setTimeout(() => {
                                                                        event.target.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                                                                    }, 0)
                                                                    setSelectChildActId(panelChildId)
                                                                    handleAnswerUpdate(childAnswer)
                                                                } else {
                                                                    setSelectChildActId(null)
                                                                }
                                                            };
                                                            const customProps = {
                                                                answerId,
                                                                answerMemberId: answerMemberId,
                                                                answerList: answerContentScheme,
                                                                questionId: selectChildActId,
                                                                memberId: memberID,
                                                                actId: sectionId,
                                                                parentQuestionId: id,
                                                                going_next: going_next,
                                                                is_dynamic: isChildDynamic,
                                                                sectionId,
                                                                questionData: {
                                                                    position,
                                                                    lastPosition: memberChildQuestion[memberChildQuestion.length - 1].position,
                                                                    lastQuestionId: memberChildQuestion[memberChildQuestion.length - 1].id,
                                                                    componentInfo: {
                                                                        question_id: childId,
                                                                        dynamic_section_id: sectionId,
                                                                        parent_question_id: id,
                                                                        member_id: memberID
                                                                    },
                                                                    actId,
                                                                    specialQuestion: false,
                                                                }
                                                            }
                                                            const reminderAttributes = {
                                                                dispatch,
                                                                childId,
                                                                sectionId,
                                                                showQuestionId: id,
                                                                has_reminders,
                                                                going_next,
                                                                showActId: sectionId,
                                                                answerId,
                                                                answerContentScheme,
                                                                memberInfo: {member_id: memberID, answerMemberId},
                                                                is_user_defined,
                                                                is_dynamic: isChildDynamic,
                                                            }
                                                            return (
                                                                <div key={childId} style={{position: "relative"}}>
                                                                    <CustomAccordion
                                                                        title={title}
                                                                        heading={heading}
                                                                        panelId={childId}
                                                                        borderSX={{
                                                                            border: "1px solid #E3E6EF",
                                                                            backgroundColor: "grey.200"
                                                                        }}
                                                                        detailsSX={{backgroundColor: "common.white"}}
                                                                        onChange={handleAccordionChange2}
                                                                        expanded={selectChildActId !== childId ? {expanded: false} : {expanded: true}}
                                                                        toolTipDesc={description}
                                                                        reminders={reminders}
                                                                        componentInfo={{
                                                                            question_id: childId,
                                                                            dynamic_section_id: sectionId,
                                                                            parent_question_id: id,
                                                                            member_id: memberID
                                                                        }}
                                                                        position={`${position}. `}
                                                                        showQuestionBtn
                                                                        memberID={memberID}
                                                                        actId={actId}
                                                                        isChildQuestion={true}
                                                                        showNotCompletedQuestion={!childAnswer || childAnswer.length === 0}
                                                                    >
                                                                        {selectChildActId === childId && answerContentScheme.map((elem, componentIndex) => (
                                                                            <AnswerContent
                                                                                {...elem}
                                                                                heading={answerHeading}
                                                                                componentIndex={componentIndex}
                                                                                customProps={customProps}
                                                                            />
                                                                        ))}
                                                                        {reminderElements(reminderAttributes).button}
                                                                    </CustomAccordion>
                                                                    {reminderElements(reminderAttributes).accRightPosition}
                                                                </div>
                                                            )
                                                        })}
                                                    </Stack>
                                            </Fragment>
                                        ): (
                                            <Fragment>
                                                {answerContentSchemeParent.map((elem, componentIndex) => (
                                                    <AnswerContent
                                                        {...elem}
                                                        heading={answerHeadingParent}
                                                        componentIndex={componentIndex}
                                                        customProps={customParentProps}
                                                    />
                                                ))}
                                                {reminderElements(reminderAttributesParent).button}
                                            </Fragment>
                                        )}
                                    </ShowYesNoBtn>
                                </Fragment>
                            ) : null}
                            </CustomAccordion>
                            {reminderElements(reminderAttributesParent).accRightPosition}
                        </div>
                    })}
                </Stack>
            ))}
            {reminderOpened && <ReminderModal />}
        </Fragment>
    )
}

export default MemberQuestions;