import React from "react"
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import TableContainer from "@mui/material/TableContainer";
import {Card} from "@mui/material";
import Button from "@mui/material/Button";
import {stepButtonSXHandler} from "../../usefuls/helpers";
import {useRouter} from "../../usefuls/hooks";
import { useSelector } from "react-redux";

const ThirdStep = () => {
    const router = useRouter();
    const { urls: { matter: wordViewUrl, case_summary_table, claim, dismissal_claim } = {} } = useSelector(state => state.matters.wordViewUrl) || {}
    const currentStep = +window.location.search.split("?step=")[1]
    const fileName = wordViewUrl?.split("/storage/")[1]
    const docs = [
        {
            uri: wordViewUrl,
            fileType: "docx",
            fileName: fileName
        },
    ];

    const handleExport = (docUrI, docFileName) => {
        const link = document.createElement("a");
        link.href = docUrI;
        link.download = docFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const handleChangeStep = (step) => {
        router.queryPush({ step })
    }

    return (
        <Card sx={{pt: "20px", pb: "20px", pr: "20px"}}>
            <TableContainer sx={{overflow: 'unset'}}>
                <DocViewer
                    documents={docs}
                    pluginRenderers={DocViewerRenderers}
                    style={{ height: 1000 }}
                />
                <Button
                    sx={{...stepButtonSXHandler({float: {float: "left"}}), marginLeft: "50px"}}
                    onClick={() => handleChangeStep(currentStep - 1)}
                >
                    Previous Step
                </Button>
                <Button
                    sx={{...stepButtonSXHandler({float: { float: "right" } }), marginRight: "50px"}}
                    onClick={() => handleExport(docs[0].uri, docs[0].fileName)}
                >
                    Export Statement
                </Button>
                <Button
                    sx={{...stepButtonSXHandler({float: { float: "right" } }), marginRight: "50px"}}
                    onClick={() => handleExport(dismissal_claim, dismissal_claim?.split("/storage/")[1])}
                >
                    Export Dismissal Statement Of Claim
                </Button>
                <Button
                    sx={{...stepButtonSXHandler({float: { float: "right" } }), marginRight: "50px"}}
                    onClick={() => handleExport(claim, claim?.split("/storage/")[1])}
                >
                    Export Statement Of Claim
                </Button>
                <Button
                    sx={{...stepButtonSXHandler({float: { float: "right" } }), marginRight: "50px"}}
                    onClick={() => handleExport(case_summary_table, case_summary_table?.split("/storage/")[1])}
                >
                    Export Case Summary Table
                </Button>
            </TableContainer>
        </Card>
    );
}

export default ThirdStep;