import React, {Fragment, useEffect} from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import { closeToastAction as authClose } from "../../store/Auth/authSlice";
import { closeToastAction as usersClose } from "../../store/Users/usersSlice";
import { closeToastAction as mattersClose } from "../../store/Matters/mattersSlice";

const whichStoreDispatchUse = {
    auth: authClose,
    users: usersClose,
    matters: mattersClose,
}

const AlertToastLayout = ({ children }) => {
    const globalStore = useSelector(state => state);
    const [sliceStoreName, { openToast, alertMessage } = { openToast: false, alertMessage: null }] =
        Object.entries(globalStore).find(elem => elem[1].openToast) || []
    const dispatch = useDispatch();
    useEffect(() => {
        let timer;
        if (openToast) {
            timer = setTimeout(() => {
                dispatch(whichStoreDispatchUse[sliceStoreName]())
            }, 4000)
        }
        return () => {
            clearTimeout(timer)
        }
    }, [dispatch, openToast, sliceStoreName]);

    return (
        <Fragment>
            {children}
            {openToast ? (
                <Box position="absolute" zIndex="999" top="100px" right="60px">
                    <Alert severity={alertMessage?.severity}>
                        {alertMessage?.message?.message}
                    </Alert>
                </Box>
            ) : null}
        </Fragment>
    )
}

export default AlertToastLayout
