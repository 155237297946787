import CustomAccordion from "../../GlobalComponents/CustomAccordion";
import React, {Fragment, useState} from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import CustomTaggable from "../../GlobalComponents/CustomTaggable";
import MemberQuestions from "./MemberQuestions";
import SpecialQuestions from "./SpecialQuestions";
import Box from "@mui/material/Box";

const DynamicSections = (
    {
        fullActInfo
    }
) => {
    const {
        act: {
            title: parentAccTitle,
            id: panelId = null,
            each_member_questions = [],
            each_member_questions_title,
            each_member_questions_question,
            special_questions = []
        } = {},
        members = [],
        id: sectionId,
        has_reminders
    } = fullActInfo;
    const [selectActId, setSelectActId] = useState(null);
    const handleAccordionChange = (event, isExpanded) => {
        if (isExpanded) {
            setSelectActId(panelId)
        } else {
            setSelectActId(null)
        }
    };
    return (
        <div style={{position: "relative"}}>
            <CustomAccordion
                title={parentAccTitle}
                panelId={String(panelId)}
                detailsSX={{display: "flex", flexWrap: "wrap", gap: "20px"}}
                borderSX={{border: "1px solid #E3E6EF"}}
                onChange={handleAccordionChange}
                expanded={panelId !== selectActId ? {expanded: false} : {expanded: true}}
            >
                {selectActId === panelId ? (
                    <Stack gap={1} width="100%">
                        {each_member_questions.length > 0 ? (
                            <Fragment>
                                <Typography>Respondents</Typography>
                                <Typography>
                                    a. {each_member_questions_question}
                                </Typography>
                                <CustomTaggable members={members} sectionId={sectionId}/>
                                {members.length > 0 && <Divider/>}
                                <MemberQuestions
                                    members={members}
                                    memberQuestions={each_member_questions}
                                    titleAboutMember={each_member_questions_title}
                                    sectionId={sectionId}
                                    actId={panelId}
                                />
                                <Divider sx={{margin: "20px 0"}}/>
                            </Fragment>
                        ) : null}
                        <SpecialQuestions specialQuestions={special_questions} sectionId={sectionId} actId={panelId}/>
                    </Stack>
                ) : null}
            </CustomAccordion>
            <Box display="flex" alignItems="center" gap="10px" position="absolute" top="20px" right="15px">
                <Box display="flex" gap="5px">
                    <Fragment>
                        {!!has_reminders.type_1 && (
                            <Box
                                width="12px"
                                height="12px"
                                borderRadius="12px"
                                sx={{bgcolor: `accReminder.type_1`}}
                            />
                        )}
                        {!!has_reminders.type_2 && (
                            <Box
                                width="12px"
                                height="12px"
                                borderRadius="12px"
                                sx={{bgcolor: `accReminder.type_2`}}
                            />
                        )}
                        {!!has_reminders.type_3 && (
                            <Box
                                width="12px"
                                height="12px"
                                borderRadius="12px"
                                sx={{bgcolor: `accReminder.type_3`}}
                            />
                        )}
                    </Fragment>
                </Box>
            </Box>
        </div>
    );
}

export default DynamicSections;
