import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import { alpha } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import { usePathname, useResponsive } from "../../usefuls/hooks";
import Scrollbar from "./Scrollbar";
import RouterLink from "../../usefuls/routes";
import BadgeIcon from '@mui/icons-material/Badge';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Button from "@mui/material/Button";
import {matterCommentOpenAction} from "../../store/Matters/mattersSlice";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";

export default function Nav({ openNav, onCloseNav }) {
    const pathname = usePathname();
    const upLg = useResponsive('up', 'lg');
    const dispatch = useDispatch();
    const { matterId } = useParams()

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderMenu = (
        <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
            {[
                {
                    title: 'employees',
                    path: '/',
                    icon: <BadgeIcon />,
                },
                {
                    title: 'matters',
                    path: '/matters',
                    icon: <NoteAddIcon />,
                },
                // {
                //     title: 'notes',
                //     path: '/notes',
                //     icon: <TextSnippetIcon />,
                // },
            ].map((item) => (
                <NavItem key={item.title} item={item} />
            ))}
        </Stack>
    );

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <img src="/images/Karni.svg" alt="Karni SVG"
                 style={{width: "52px", height: "40px", display: 'inline-flex', margin: "20px"}}/>
            {renderMenu}
            <Box sx={{flexGrow: 1}}/>
            {matterId && (
                <Button
                    sx={{
                        p: "10px 20px",
                        ml: "5%",
                        width: "max-content",
                        position: "absolute",
                        bottom: "50px",
                        backgroundColor: "primary.lighter"
                    }}
                    onClick={() => {
                        dispatch(matterCommentOpenAction({ id: matterId, open: true }))
                    }}
                >
                    Matter Note
                </Button>
            )}
        </Scrollbar>
    );

    return (
        <Box
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: 280 },
                backgroundColor: "grey.0"
            }}
        >
            {upLg ? (
                <Box
                    sx={{
                        height: 1,
                        position: 'fixed',
                        width: 280,
                        borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
                    }}
                >
                    {renderContent}
                </Box>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    PaperProps={{
                        sx: {
                            width: 280,
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}

function NavItem({ item }) {
    const pathname = usePathname();
    const active = pathname.includes("/matter") && item.path === "/matters" ? true : item.path === pathname;

    return (
        <ListItemButton
            component={RouterLink}
            href={item.path}
            sx={{
                minHeight: 44,
                borderRadius: 0.75,
                typography: 'body2',
                color: 'text.secondary',
                textTransform: 'capitalize',
                fontWeight: 'fontWeightMedium',
                ...(active && {
                    color: 'primary.main',
                    fontWeight: 'fontWeightSemiBold',
                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                    '&:hover': {
                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
                    },
                }),
            }}
        >
            <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
                {item.icon}
            </Box>
            <Box component="span">{item.title} </Box>
        </ListItemButton>
    );
}
