import {useEffect, useState} from 'react';
import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Iconify from "../ui-kit/Iconify";
import { resetUserData, userDeleteReq } from "../../store/Users/usersSlice";
import {useDispatch} from "react-redux";
import {useRouter} from "../../usefuls/hooks";
import {matterCommentOpenAction, matterDeleteReq} from "../../store/Matters/mattersSlice";
import RateReviewIcon from '@mui/icons-material/RateReview';

export default function UserTableRow({
    name,
    email,
    phoneNumber,
    date,
    role,
    id,
    employees,
    matterId
}) {
    const [open, setOpen] = useState(null);
    const dispatch = useDispatch();
    const router = useRouter();
    useEffect(() => {
        dispatch(resetUserData())
    }, [dispatch]);
    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };
    const getEditableUserId = (id) => {
        if (employees) {
            router.push(`/matter/${id}?step=1`)
        } else {
            router.push(`/edit-user/${id}`)
        }
    }
    const deleteCurrUser = (id) => {
        if (employees) {
            dispatch(matterDeleteReq(id))
        } else {
            dispatch(userDeleteReq(id))
        }
    }
    return (
        <>
            <TableRow hover tabIndex={-1} role="checkbox" selected={false}>
                <TableCell component="th" scope="row" padding="none">
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography variant="subtitle2" noWrap>{name}</Typography>
                    </Stack>
                </TableCell>
                <TableCell>{email}</TableCell>
                <TableCell>{phoneNumber}</TableCell>
                {employees && <TableCell>{employees.employee}{!!employees.length && `, +${employees.length}`}</TableCell>}
                <TableCell>{date}</TableCell>
                {role === "admin" && (
                    <TableCell align="right">
                        <IconButton onClick={handleOpenMenu}>
                            <Iconify icon="eva:more-vertical-fill" />
                        </IconButton>
                    </TableCell>
                )}
            </TableRow>
            <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{ sx: { width: 140 } }}
            >
                <MenuItem onClick={() => {
                    getEditableUserId(id)
                    handleCloseMenu()
                }}>
                    <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
                    Edit
                </MenuItem>
                <MenuItem onClick={() => {
                    deleteCurrUser(id);
                    handleCloseMenu()
                }} sx={{ color: 'error.main' }}>
                    <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
                <MenuItem onClick={() => {
                    dispatch(matterCommentOpenAction({ id: matterId, open: true }))
                    handleCloseMenu()
                }}>
                    <RateReviewIcon sx={{ mr: 2 }}/>
                    Note
                </MenuItem>
            </Popover>
        </>
    );
}
