import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {Controller, useForm} from "react-hook-form";
import { editQuestionWithIdAction, updateQuestionReq } from "../../../store/Matters/mattersSlice";
import Box from "@mui/material/Box";

const NewQuestionFields = ({ questionId, defaultValues, bodyQuestionFields, ...addQuestionBody }) => {
    const dispatch = useDispatch();
    const {
        control,
        setValue,
        handleSubmit,
        // watch,
        // formState: { isSubmitSuccessful }
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    useEffect(() => {
        if (defaultValues) {
            setValue("question", defaultValues)
        }
    }, [defaultValues, setValue]);

    const onSubmit = (data) => {
        let label = {}
        if (!!data.question.title) {
            label.title = data.question.title
        }
        if (!!data.question.description) {
            label.description = data.question.description
        }
        // if (!!data.question.heading) {
        //     label.heading = data.question.heading
        // }
        dispatch(updateQuestionReq({
            questionId,
            ...label,
            ...addQuestionBody,
        }))
        dispatch(editQuestionWithIdAction(null))
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(onSubmit)();
        }
    };
    const {
        editMember_id,
        memberID,
        editParentQuestionId,
        forEditParentId,
        editDynamicSectionId,
        dynamic_section_id,
        question_id,
        editQuestionId
    } = bodyQuestionFields

    return (
        <Box display="flex" flexDirection="column" width="100%" ml="10px">
            {
                // (!isSubmitSuccessful || !(!!watch().question.title))
                (!defaultValues.title || (
                    editMember_id === memberID
                    && editParentQuestionId === forEditParentId
                    && editDynamicSectionId === dynamic_section_id
                    && editQuestionId === question_id
                )) && (
                    <Controller
                        control={control}
                        name={`question.title`}
                        render={({field: {onChange, value, ref, onBlur}}) => (
                            <textarea
                                name="title"
                                style={{height: "80px", width: "100%", backgroundColor: "#FFFFFF", display: "inline"}}
                                cols="30"
                                rows="10"
                                placeholder="Type question"
                                value={value}
                                onBlur={onBlur}
                                onChange={onChange}
                                onClick={(e) => e.stopPropagation()}
                                ref={ref}
                                autoComplete="off"
                                onKeyPress={handleKeyPress}
                            />
                        )}
                    />
                )
            }
            {
                (!defaultValues.description || (
                    editMember_id === memberID
                    && editParentQuestionId === forEditParentId
                    && editDynamicSectionId === dynamic_section_id
                    && editQuestionId === question_id
                ))
                && (
                    <Controller
                        control={control}
                        name={`question.description`}
                        render={({field: {onChange, value, ref, onBlur}}) => (
                            <textarea
                                name="description"
                                style={{height: "80px", width: "100%", backgroundColor: "#FFFFFF", display: "inline"}}
                                cols="30"
                                rows="10"
                                placeholder="Type description"
                                value={value}
                                onBlur={onBlur}
                                onChange={onChange}
                                onClick={(e) => e.stopPropagation()}
                                ref={ref}
                                autoComplete="off"
                                onKeyPress={handleKeyPress}
                            />
                        )}
                    />
                )
            }
            {/* {
                (!isSubmitSuccessful || !(!!watch().question.heading)) && (
                    <Controller
                        control={control}
                        name={`question.heading`}
                        render={({field: {onChange, value, ref, onBlur}}) => (
                            <textarea
                                name="heading"
                                style={{height: "80px", width: "100%", backgroundColor: "#FFFFFF", display: "inline"}}
                                cols="30"
                                rows="10"
                                placeholder="Type Heading"
                                value={value}
                                onBlur={onBlur}
                                onChange={onChange}
                                onClick={(e) => e.stopPropagation()}
                                ref={ref}
                                autoComplete="off"
                                onKeyPress={handleKeyPress}
                            />
                        )}
                    />
                )
            } */}
        </Box>
    )
}

export default NewQuestionFields;