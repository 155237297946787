import React, {useEffect, Fragment, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import { useDispatch } from "react-redux";
import {useParams} from "react-router-dom";
import CustomAnswerList from "./CustomAnswerList";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {debounce, TextField} from "@mui/material";
import {requestMemberComponentsSync} from "../../usefuls/helpers";

const CustomNote = (
    {
        note,
        componentIndex,
        answerId = null,
        answerMemberId,
        answerList,
        going_next,
        staticSection,
        ...customProps
    }
) =>  {
    const dispatch = useDispatch();
    const { matterId } = useParams();
    const [initialDefaultSet, setInitialDefaultSet] = useState(false)
    const {
        control,
        setValue,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })
    useEffect(() => {
        if (!initialDefaultSet && note) {
            setValue("note", note);
            setInitialDefaultSet(true);
        }
    }, [note, setValue, initialDefaultSet]);

    const debouncedOnChange = debounce((e) => {
        let updatedData = [...answerList];
        updatedData[componentIndex] = { note: { id: note.id, value: e.target.value, ai_config: e.target.placeholder === "Text input" ? false : note.ai_config } };
        requestMemberComponentsSync(
            {
                customProps,
                matterId,
                updatedData,
                answerId: !!customProps.memberId ? answerMemberId === customProps.memberId ? answerId : null : answerId,
                dispatch,
                going_next,
                staticSection
            }
        )
    }, 500);

    return (
        <Fragment>
            <Stack gap={2} mt="20px" width="90%">
                {!!customProps.is_dynamic && <Typography>Note</Typography>}
                <Controller
                    control={control}
                    name={`note.value`}
                    render={({field: {onChange, value, ref, onBlur}}) => {
                        if (!(!!customProps.is_dynamic)) {
                            return (
                                <TextField
                                    name="note"
                                    id={`${note.id}`}
                                    placeholder="Text input"
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={(e) => {
                                        onChange(e);
                                        debouncedOnChange(e)
                                    }}
                                    inputRef={ref}
                                    autoComplete="off"
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ mb: "20px" }}
                                />
                            )
                        }
                        return (
                            <textarea
                                name="note"
                                id={`${note.id}`}
                                cols="30"
                                rows="10"
                                placeholder="Text area"
                                value={value}
                                onBlur={onBlur}
                                onChange={(e) => {
                                    onChange(e);
                                    debouncedOnChange(e)
                                }}
                                ref={ref}
                                autoComplete="off"
                                style={{ height: "220px" }}
                            />
                        )
                    }}/>
            </Stack>
            {!!customProps.is_dynamic && (
                <CustomAnswerList
                    componentIndex={componentIndex}
                    answerList={answerList}
                    answerId={answerId}
                    going_next={going_next}
                    staticSection={staticSection}
                    answerMemberId={answerMemberId}
                    showAI={true}
                    {...customProps}
                />
            )}
        </Fragment>
    )
}

export default CustomNote;