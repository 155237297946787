import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {requestMemberComponentsSync} from "../../usefuls/helpers";
import {useDispatch} from "react-redux";

const CustomSelect = (
    {
        members,
        memberIndex,
        answerList,
        componentIndex,
        matterId,
        answerId,
        customProps,
        currName,
        staticSection
    }
) => {
    const dispatch = useDispatch()

    const handleChange = (event) => {
        const updatedData = [...answerList];
        updatedData[componentIndex].dialog.dialogValues[memberIndex].whoSad = event.target.value
        requestMemberComponentsSync(
    {
                customProps,
                matterId,
                updatedData,
                answerId,
                dispatch,
                listItemHandle: true,
                staticSection
            }
        )
    };
    return (
        <FormControl sx={{ m: 1, minWidth: 180, margin: 0 }} size="small">
            <InputLabel  id="demo-select-small-label">Who said?</InputLabel>
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={currName}
                label="Member"
                onChange={handleChange}
                autoComplete="off"
            >
                {[{id: 0, name: "I said"}, ...members].map(({ id, name }) => (
                    <MenuItem value={id}>{name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default CustomSelect;
