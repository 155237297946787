import {Fragment, useState} from 'react';
import Box from '@mui/material/Box';
import Nav from './Navigation';
import Header from "./Header";
import Main from "./Main";

export default function DashboardLayout({ children }) {
    const [openNav, setOpenNav] = useState(false);
    return (
        <Fragment>
            <Header onOpenNav={() => setOpenNav(true)} />
            <Box
                sx={{
                    minHeight: 1,
                    display: 'flex',
                    flexDirection: { xs: 'column', lg: 'row' },
                }}
            >
                <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />
                <Main>{children}</Main>
            </Box>
        </Fragment>
    );
}
