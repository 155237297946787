import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'white',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    maxHeight: "700px",
    overflowY: "auto"
};

const TermsModal = (
    {
        termsOpened,
        setTermsOpened
    }
) => {
    return (
        <Modal
            open={termsOpened}
            onClose={() => setTermsOpened(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{ sx: { bgcolor: 'rgba(0, 0, 0, 0.3)' } }}
        >
            <Box sx={style}>
                <Stack gap={5} >
                    <Typography variant="h4" margin="0 auto">Interview Guidelines</Typography>
                    <Box>
                        <b style={{
                            display: "inherit",
                            fontWeight: 'bold',
                            textAlign: "center",
                            textDecoration: "underline"
                        }}>Discrimination Interview Guidelines for the Interviewer</b>

                        <br/>
                        <span style={{fontWeight: 400}}>  </span>

                        <b style={{fontWeight: 'bold'}}>Dear interviewer, below you will find extensive questions
                            regarding discrimination, which will help you conduct more in-depth interviews. </b>
                        <span style={{fontWeight: 400}}> </span>

                        <span style={{fontWeight: 400}}>  </span>

                        <br/>
                        <br/>
                        <b style={{fontWeight: 'bold'}}>Direct Speech </b><span style={{fontWeight: 400}}> </span>
                        <br/>
                        <br/>
                        <span style={{fontWeight: 400}}>  </span>

                        <span style={{fontWeight: 400}}>What is crucial is you record and capture the direct speech, what direct speech means, is all the comments that our client experienced and heard by the individual or group of employees who made the discriminatory comments. The more in-depth the direct speech is, the more we can help our client.  </span>

                        <span style={{fontWeight: 400}}>  </span>
                        <br/>
                        <br/>

                        <b style={{fontWeight: 'bold'}}>Dates and Times and Locations and the Context of the
                            Incident</b>
                        <br/>
                        <br/>

                        <span style={{fontWeight: 400}}>  </span>

                        <span style={{fontWeight: 400}}>Please ask in-depth information, as to the dates and times and location of the discriminatory comments, and the regularity, and the context. If the client cannot give you specific dates and times, not an issue, ask for an approximate time.</span>

                        <span style={{fontWeight: 400}}>  </span>

                        <br/>
                        <br/>

                        <b style={{fontWeight: 'bold', textDecoration: "underline"}}>Please ensure you ask who was
                            present when the comments were
                            made</b><span style={{fontWeight: 400}}> </span>

                        <br/>
                        <br/>

                        <span style={{fontWeight: 400}}>  </span>

                        <b style={{fontWeight: 'bold'}}>Diary Notes and Record </b><span
                        style={{fontWeight: 400}}> </span>
                        <br/>
                        <br/>

                        <span style={{fontWeight: 400}}>  </span>

                        <span style={{fontWeight: 400}}>Ask the client if they kept any diary notes and records, or any other documents to prove the discrimination.  </span>

                        <span style={{fontWeight: 400}}>  </span>
                        <br/>
                        <br/>
                        <hr/>

                        <b style={{
                            display: "inherit",
                            fontWeight: 'bold',
                            textAlign: "center",
                            textDecoration: "underline"
                        }}>Discrimination Interview Guidelines for the Client</b>
                        <br/>


                        <b style={{fontWeight: 'bold'}}> </b>

                        <b style={{fontWeight: 'bold'}}>We are now going to be asking you some very specific questions
                            regarding the discrimination you experienced at work.</b>


                        <b style={{fontWeight: 'bold'}}>We are going to cover all aspects of your discrimination within
                            a course of 2-4 interviews, hence please try to stick to the question being asked, so as we
                            would keep better track of your experiences and would not miss or lose any information.</b>


                        <b style={{fontWeight: 'bold'}}>We understand that recalling experiences of discrimination and
                            harassment can be overwhelming and upsetting, hence if at any point you need a break, just
                            let me know.</b>

                        <b style={{fontWeight: 'bold'}}>Keep in mind that I will am typing all information, so please
                            bear with me if at times I ask to repeat the answer or talk slower.</b>
                        <br/>
                        <br/>
                        <hr/>

                        <b style={{
                            display: "inherit",
                            fontWeight: 'bold',
                            textAlign: "center",
                            textDecoration: "underline"
                        }}>THE IMPORTANCE OF FACTUAL CHRONOLOGY</b><b
                        style={{fontWeight: 'bold'}}> </b>
                        <br/>


                        <b style={{fontWeight: 'bold'}}>The details are very important for your statement, hence please
                            bear with me as I will be asking specific details. </b>
                        <br/>
                        <br/>


                        <b style={{fontWeight: 'bold'}}>It is very important that you provide us detailed information,
                            such as; </b>
                        <br/>

                        <b style={{fontWeight: 'bold'}}> </b>
                        <ul>
                            <li aria-level="1"><b style={{fontWeight: 'bold'}}>Name and surname and position of any
                                individual), who made racially hurtful and offensive comments towards you;</b></li>
                        </ul>
                        <b style={{fontWeight: 'bold'}}> </b>
                        <ul>
                            <li aria-level="1"><b style={{fontWeight: 'bold'}}>The time and the location of the
                                discriminatory comments and incidents; </b></li>
                        </ul>
                        <b style={{fontWeight: 'bold'}}></b>
                        <b style={{fontWeight: 'bold'}}></b>
                        <ul>
                            <li aria-level="1"><b style={{fontWeight: 'bold'}}>The body language and tone of the
                                individual who made the discriminatory comment or conduct;</b></li>
                        </ul>
                        <b style={{fontWeight: 'bold'}}></b>
                        <b style={{fontWeight: 'bold'}}></b>
                        <ul>
                            <li aria-level="1"><b style={{fontWeight: 'bold'}}>The direct speech and conversations;</b>
                            </li>
                        </ul>
                        <b style={{fontWeight: 'bold'}}> </b>
                        <ul>
                            <li aria-level="1"><b style={{fontWeight: 'bold'}}>Details of the witnesses of the
                                discriminatory comments and conduct;</b></li>
                        </ul>
                        <b style={{fontWeight: 'bold'}}> </b>
                        <ul>
                            <li aria-level="1"><b style={{fontWeight: 'bold'}}>What was your reaction (e.g. did you tell
                                the person to back off) and what was the conversation following the incident? </b></li>
                        </ul>
                        <b style={{fontWeight: 'bold'}}> </b>
                        <ul>
                            <li aria-level="1"><b style={{fontWeight: 'bold'}}>What was the reaction of your
                                fellow-employees? </b></li>
                        </ul>
                        <b style={{fontWeight: 'bold'}}> </b>

                        <b style={{fontWeight: 'bold'}}> </b>

                        <b style={{fontWeight: 'bold'}}>The more detailed information you provide, the better it will
                            assist us to structure and write a strong statement regarding the discrimination and
                            harassment that you suffered, but do not worry, we are going to guide and lead you
                            throughout the interview and we will be asking all the necessary questions.</b>
                    </Box>


                    <LoadingButton
                        sx={{
                            backgroundColor: "black.100",
                            color: "common.white",
                            p: "10px 50px",
                            borderRadius: "4px",
                            width: "max-content",
                            margin: "0 auto"
                        }}
                        onClick={() => setTermsOpened(false)}
                    >
                        Accept
                    </LoadingButton>
                </Stack>
            </Box>
        </Modal>
    )
}

export default TermsModal;
