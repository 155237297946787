import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import { axiosClient as axios } from "../../requests/axiosClient";

export const loginUserReq = createAsyncThunk(
    'auth/loginUserReq',
    async ({ email, password }, { rejectWithValue }) => {
        try {
            const { data } = await axios.post(`api/login`, { email, password });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);
export const forgotPasswordReq = createAsyncThunk(
    'auth/forgotPasswordReq',
    async ({ email }, { rejectWithValue }) => {
        try {
            const { data } = await axios.post(`api/forgot`, { email });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);
export const resetPasswordReq = createAsyncThunk(
    'auth/resetPasswordReq',
    async (resetData, { rejectWithValue }) => {
        try {
            const { data } = await axios.post(`api/reset`, resetData);
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

export const logOutReq = createAsyncThunk(
    'auth/logOutReq',
    async (resetData, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.post(`api/logout`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

const initialState = {
    openToast: false,
    loading: false,
    userInfo: JSON.parse(localStorage.getItem("userInfo")) || null,
    alertMessage: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearUserInfoAction: (state) => {
            state.userInfo = null
        },
        closeToastAction: (state) => {
            state.openToast = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUserReq.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginUserReq.fulfilled, (state, action) => {
                const userInfo = action.payload || {}
                state.loading = false;
                state.userInfo = userInfo
                localStorage.setItem("userInfo", JSON.stringify(userInfo));
            })
            .addCase(loginUserReq.rejected, (state, action) => {
                state.loading = false;
                state.alertMessage = {message: action.payload, severity: "error"}
                state.openToast = true;
                localStorage.removeItem("userInfo")
                state.error = action.error?.message;
            })
            .addCase(forgotPasswordReq.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(forgotPasswordReq.fulfilled, (state, action) => {
                state.loading = false;
                state.alertMessage = {message: action.payload , severity: "success"}
                state.openToast = true;
            })
            .addCase(forgotPasswordReq.rejected, (state, action) => {
                state.loading = false;
                state.alertMessage = {message: action.payload, severity: "error"}
                state.openToast = true;
                state.error = action.error?.message;
            })
            .addCase(resetPasswordReq.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(resetPasswordReq.fulfilled, (state, action) => {
                state.loading = false;
                state.alertMessage = {message: action.payload, severity: "success"}
                state.openToast = true;
            })
            .addCase(resetPasswordReq.rejected, (state, action) => {
                state.loading = false;
                state.alertMessage = {message: action.payload, severity: "error"}
                state.openToast = true;
                state.error = action.error?.message;
            })
            .addCase(logOutReq.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(logOutReq.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(logOutReq.rejected, (state, action) => {
                state.loading = false;
                state.alertMessage = {message: action.payload, severity: "error"}
                state.openToast = true;
                state.error = action.error?.message;
            })
    },
})

export const { clearUserInfoAction, closeToastAction } = authSlice.actions

export default authSlice.reducer