import {bgGradient} from "../../theme/css";
import React, { useCallback } from "react";
import {alpha, useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {Card, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import {Link as ReactLink} from "react-router-dom"
import {Controller, useForm} from "react-hook-form";
import { forgotPasswordReq } from "../../store/Auth/authSlice";
import { useDispatch } from "react-redux";

const ForgotPassword = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { handleSubmit, control } = useForm({
        defaultValues: { email: "" },
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    const onSubmit = useCallback((data) => {
        dispatch(forgotPasswordReq(data));
    }, [dispatch])

    return (
        <Box
            sx={{
                ...bgGradient({
                    color: alpha(theme.palette.background.default, 0.9),
                    imgUrl: '/assets/background/overlay_4.jpg',
                }),
                height: 1,
            }}
        >
            <img
                src="/images/Karni.svg" alt="Karni SVG"
                style={{width: "52px", height: "40px", display: 'inline-flex', margin: "20px"}}
            />
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{height: "80vh"}}
                as="form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Card sx={{ p: 5, width: 1, maxWidth: 420 }}>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h4" sx={{mt: 2, mb: 5}}>Forgot password</Typography>
                    </Box>
                    <Stack spacing={3}>
                        <Controller
                            control={control}
                            name="email"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TextField
                                    name="email"
                                    label="Email address"
                                    type="email"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    ref={ref}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                    </Stack>
                    <LoadingButton
                        sx={{my: 3}}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="inherit"
                    >
                        Send
                    </LoadingButton>
                    <Stack direction="row" alignItems="center" justifyContent="center" >
                        <Link variant="subtitle2" underline="hover" >
                            <ReactLink to="/signin">Back to Sign in</ReactLink>
                        </Link>
                    </Stack>
                </Card>
            </Stack>
        </Box>
    )
}

export default ForgotPassword;