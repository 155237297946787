import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import { axiosClient as axios } from "../../requests/axiosClient";

export const usersListReq = createAsyncThunk(
    'users/usersListReq',
    async (queryParams, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token, role } = {} } } = getState();
            const { data } = await axios.get(`api/${role === "user" ? "user-list" : "users"}${queryParams}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

export const userCreateReq = createAsyncThunk(
    'users/userCreateReq',
    async (body, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.post(`api/users`, body ,{
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);
export const userDeleteReq = createAsyncThunk(
    'users/userDeleteReq',
    async (id, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.delete(`api/users/${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);
export const currentUserReq = createAsyncThunk(
    'users/currentUserReq',
    async (_, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.get(`api/me`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);
export const userByIdReq = createAsyncThunk(
    'users/userByIdReq',
    async (id, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.get(`api/users/${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

export const editUserByIdReq = createAsyncThunk(
    'users/editUserByIdReq',
    async ({ id, ...body}, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.put(`api/users/${id}`,body, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

export const getMyProfileDataReq = createAsyncThunk(
    'users/getMyProfileDataReq',
    async (_, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.get(`api/me`,{
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

export const editMyProfileDataReq = createAsyncThunk(
    'users/editMyProfileDataReq',
    async (body, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.post(`api/me`, body, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

const initialState = {
    openToast: false,
    loading: false,
    alertMessage: null,
    usersList: null,
    currentUser: null,
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        resetUserData: (state) => {
            state.currentUser = null
        },
        closeToastAction: (state) => {
            state.openToast = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(usersListReq.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(usersListReq.fulfilled, (state, action) => {
                state.loading = false;
                state.usersList = action.payload
            })
            .addCase(usersListReq.rejected, (state, action) => {
                state.loading = false;
                state.alertMessage = { message: action.payload, severity: "error" }
                state.openToast = true;
                state.error = action.error?.message;
            })
            .addCase(userCreateReq.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(userCreateReq.fulfilled, (state, action) => {
                state.loading = false;
                state.alertMessage = { message: action.payload, severity: "success" }
                state.openToast = true;
            })
            .addCase(userCreateReq.rejected, (state, action) => {
                state.loading = false;
                state.alertMessage = { message: action.payload, severity: "error" }
                state.openToast = true;
                state.error = action.error?.message;
            })
            .addCase(userDeleteReq.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(userDeleteReq.fulfilled, (state, action) => {
                state.loading = false;
                state.usersList = action.payload
            })
            .addCase(userDeleteReq.rejected, (state, action) => {
                state.loading = false;
                state.alertMessage = { message: action.payload, severity: "error" }
                state.openToast = true;
                state.error = action.error?.message;
            })
            .addCase(currentUserReq.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(currentUserReq.fulfilled, (state, action) => {
                state.loading = false;
                state.currentUser = action.payload
            })
            .addCase(currentUserReq.rejected, (state, action) => {
                state.loading = false;
                state.alertMessage = { message: action.payload, severity: "error" }
                state.openToast = true;
                state.error = action.error?.message;
            })
            .addCase(userByIdReq.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(userByIdReq.fulfilled, (state, action) => {
                state.loading = false;
                state.currentUser = action.payload
            })
            .addCase(userByIdReq.rejected, (state, action) => {
                state.loading = false;
                state.alertMessage = { message: action.payload, severity: "error" }
                state.openToast = true;
                state.error = action.error?.message;
            })
            .addCase(editUserByIdReq.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(editUserByIdReq.fulfilled, (state, action) => {
                state.loading = false;
                state.currentUser = null;
                state.alertMessage = { message: action.payload, severity: "success" }
                state.openToast = true;
            })
            .addCase(editUserByIdReq.rejected, (state, action) => {
                state.loading = false;
                state.alertMessage = { message: action.payload, severity: "error" }
                state.openToast = true;
                state.error = action.error?.message;
            })
            .addCase(getMyProfileDataReq.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getMyProfileDataReq.fulfilled, (state, action) => {
                state.loading = false;
                state.currentUser = action.payload
            })
            .addCase(getMyProfileDataReq.rejected, (state, action) => {
                state.loading = false;
                state.alertMessage = { message: action.payload, severity: "error" }
                state.openToast = true;
                state.error = action.error?.message;
            })
            .addCase(editMyProfileDataReq.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(editMyProfileDataReq.fulfilled, (state, action) => {
                state.loading = false;
                state.currentUser = null;
                state.alertMessage = { message: action.payload, severity: "success" }
                state.openToast = true;
            })
            .addCase(editMyProfileDataReq.rejected, (state, action) => {
                state.loading = false;
                state.alertMessage = { message: action.payload, severity: "error" }
                state.openToast = true;
                state.error = action.error?.message;
            })
    },
})

export const { closeToastAction, resetUserData } = usersSlice.actions

export default usersSlice.reducer