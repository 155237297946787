import { useDispatch } from "react-redux";
import React, { Fragment } from "react";
import Button from "@mui/material/Button";
import {requestMemberComponentsSync} from "../../../usefuls/helpers";
import {useParams} from "react-router-dom";

const ShowYesNoBtn = (
    {
        customProps,
        staticSection,
        going_next,
        answer,
        has_yes_no,
        children //ReactNode
    }
) => {
    const { matterId } = useParams();
    const dispatch= useDispatch();

    const handleClickYesBtn = (yesOrNo) => {
        requestMemberComponentsSync(
            {
                customProps,
                matterId,
                dispatch,
                going_next,
                staticSection,
                yes: yesOrNo,
                answerId: answer[0]?.id
            }
        )
    }
    console.log(answer[0], "has_yes_no && ((!answer || answer.length === 0) || answer[0]?.yes === 0)123")

    if (has_yes_no && ((!answer || answer.length === 0) || answer[0]?.yes === 0)) {
        return <Fragment>
            <Button onClick={() => handleClickYesBtn(1)} sx={{backgroundColor: "info.dark", color: "common.white"}}>
                Yes
            </Button>
            <Button onClick={() => handleClickYesBtn(0)} sx={{backgroundColor: "grey.300", color: "black.200", ml: "10px"}}>
                No
            </Button>
        </Fragment>
    } else {
        return children
    }
}

export default ShowYesNoBtn;
