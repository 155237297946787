import Box from '@mui/material/Box';
import {useResponsive} from "../../usefuls/hooks";

export default function Main({ children, sx, ...other }) {
    const lgUp = useResponsive('up', 'lg');
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                minHeight: 1,
                display: 'flex',
                flexDirection: 'column',
                py: `${88}px`,
                ...(lgUp && {
                    px: 2,
                    py: `${88}px`,
                    width: `calc(100% - ${280}px)`,
                }),
                ...sx,
            }}
            {...other}
        >
            {children}
        </Box>
    );
}
