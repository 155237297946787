import React, {useCallback } from "react";
import { useDispatch } from "react-redux";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TableContainer from "@mui/material/TableContainer";
import PersonIcon from '@mui/icons-material/Person';
import ListItemButton from "@mui/material/ListItemButton";
import Box from "@mui/material/Box";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {userCreateReq} from "../../store/Users/usersSlice";
import {useRouter} from "../../usefuls/hooks";

export default function NewEmployee() {
    const dispatch = useDispatch();
    const router = useRouter()
    const { handleSubmit, control, reset } = useForm({
        defaultValues: {
            users: [
                { name: "", keyVal: "name", label: "Full Name:", type: "text" },
                { email: "", keyVal: "email", label: "Email Address:", type: "email" },
                { phone: "", keyVal: "phone", label: "Phone Number:", type: "number" },
                { password: "", keyVal: "password", label: "Password:", type: "password" },
                { password_confirmation: "", keyVal: "password_confirmation", label: "Confirm password", type: "password" },
            ],
        },
        mode: "onBlur",
        reValidateMode: "onBlur"
    })
    const { fields } = useFieldArray({ control, name: "users" });
    const onSubmit = useCallback(async (data) => {
        const usersData = {};
        data.users.forEach(({ keyVal }, index) => {
            usersData[keyVal] = data.users[index][keyVal]
        })
        const payloadOut = await dispatch(userCreateReq(usersData));
        if (payloadOut.error?.message !== "Rejected") {
            router.push("/")
        }
    }, [dispatch, router])

    return (
        <Container>
            <Stack  direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4">Users</Typography>
            </Stack>
            <Card sx={{ pt: "20px", pb: "20px", pr: "20px" }}>
                <TableContainer sx={{ overflow: 'unset' }}>
                    <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
                        <ListItemButton
                            sx={{
                                minHeight: 44,
                                borderRadius: 0.75,
                                typography: 'body2',
                                textTransform: 'capitalize',
                                color: 'primary.main',
                                fontWeight: 'fontWeightSemiBold',
                                p: "20px 0"
                            }}
                            divider
                        >
                            <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
                                <PersonIcon />
                            </Box>
                            <Box component="span">personal info</Box>
                        </ListItemButton>
                    </Stack>
                    <Box pt="20px" display="flex" justifyContent="center">
                        <Typography width="630px" variant="h4" sx={{mt: 2, mb: 5}}>Personal Information</Typography>
                    </Box>
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        as="form"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Stack width="630px" spacing={3}>
                            {fields.map(({ keyVal, label, type }, index) => (
                                <Controller
                                    key={index}
                                    control={control}
                                    name={`users.${index}.${keyVal}`}
                                    render={({field: {onChange, onBlur, value, ref}}) => (
                                        <TextField
                                            name={keyVal}
                                            label={label}
                                            type={type}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            ))}
                            <Box display="flex" justifyContent="flex-end">
                                <Box display="flex" justifyContent="space-between">
                                    <LoadingButton
                                        onClick={() => reset()}
                                        sx={{
                                            width: "80px",
                                            height: "46px",
                                            bgcolor: "grey.300",
                                            color: "black.200"
                                        }}
                                    >
                                        Reset
                                    </LoadingButton>
                                    <LoadingButton
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        color="inherit"
                                        sx={{
                                            ml: "10px",
                                            bgcolor: "black.100",
                                            color: "common.white",
                                            width: "80px",
                                            height: "46px",
                                        }}
                                    >
                                        Save
                                    </LoadingButton>
                                </Box>
                            </Box>
                        </Stack>
                    </Stack>
                </TableContainer>
            </Card>
        </Container>
    );
}
