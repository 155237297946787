import React, {useCallback, useEffect} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import {useDispatch, useSelector} from "react-redux";
import {addMatterCommentReq, getMatterCommentReq, matterCommentOpenAction} from "../../store/Matters/mattersSlice";
import {Controller, useForm} from "react-hook-form";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'white',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    maxHeight: "700px",
    overflowY: "auto"
};

const CommentModal = () => {
    const { open, id: matterId } = useSelector(state => state.matters.openedMatterCommentModal) || {};
    const dispatch = useDispatch();
    const {
        control,
        setValue,
        getValues
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    useEffect( () => {
        (async () => {
            if (open && matterId) {
                const { payload } = await dispatch(getMatterCommentReq(matterId)) || {}
                if (+matterId === +payload.matterId) {
                    setValue("comment", payload.content);
                }
            }
        })()
    }, [dispatch, matterId, open, setValue]);

    const handleClick = useCallback(() => {
        dispatch(addMatterCommentReq({id: matterId, content: getValues().comment}))
        dispatch(matterCommentOpenAction({ id: null, open: false }));
        setValue("comment", null);
    }, [dispatch, getValues, matterId, setValue])
    return (
        <Modal
            open={open}
            onClose={() => {
                dispatch(matterCommentOpenAction({id: null, open: false}));
                setValue("comment", null);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{ sx: { bgcolor: 'rgba(0, 0, 0, 0.3)' } }}
        >
            <Box sx={style}>
                <Stack gap={5} >
                    <Typography variant="h4" margin="0 auto">Note</Typography>
                    <Controller
                        control={control}
                        name="comment"
                        render={({field: {onChange, value, ref, onBlur}}) => {
                            return (
                                <textarea
                                    name="note"
                                    cols="30"
                                    rows="10"
                                    placeholder="Text area"
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    ref={ref}
                                    autoComplete="off"
                                    style={{ height: "350px" }}
                                />
                            )
                        }}/>
                    <LoadingButton
                        sx={{
                            backgroundColor: "black.100",
                            color: "common.white",
                            p: "10px 50px",
                            borderRadius: "4px",
                            width: "max-content",
                            margin: "0 auto"
                        }}
                        onClick={handleClick}
                    >
                        Save
                    </LoadingButton>
                </Stack>
            </Box>
        </Modal>
    )
}

export default CommentModal;
