import {
    answerContentCreateReq,
    answerContentUpdateReq,
    answerUpdateAction,
} from "../store/Matters/mattersSlice";

export const requestMemberComponentsSync = (
    {
        customProps,
        matterId,
        updatedData,
        answerId,
        dispatch,
        listItemHandle,
        going_next = true,
        staticSection = false,
        yes = 1,
        ai_configs = null,
        heading
    }
) => {
    const sectionIdHandle = staticSection
        ? { static_section_id: customProps.sectionId }
        : { dynamic_section_id: customProps.sectionId }
    const parentQuestionHandle = customProps.parentQuestionId
        ? { parent_question_id: customProps.parentQuestionId }
        : {}
    const aiConfigHandle = ai_configs ? { ai_configs } : {}
    const body = {
        going_next: !!going_next,
        heading,
        ...sectionIdHandle,
        ...parentQuestionHandle,
        ...aiConfigHandle,
        question_id: customProps.questionId,
        member_id: customProps.memberId,
        matter_id: +matterId,
        yes,
        content: updatedData ? JSON.stringify({ list: updatedData }) : null
    }
    if (!listItemHandle && !answerId) {
        return dispatch(answerContentCreateReq(body))
    } else {
        dispatch(answerUpdateAction({ data: body, is_foreach_member: !!customProps.memberId, is_in_dynamic_section: !(!!staticSection) }))
        return dispatch(answerContentUpdateReq({ id: answerId, ...body }))
    }
}

export const stepButtonSXHandler = (
    {
        backgroundColor = "info.dark",
        marginLeft = {},
        float = {}
    }
) => {
    return {
        backgroundColor,
        color: "common.white",
        padding: "10px 20px",
        marginTop: "20px",
        marginBottom: "20px",
        ...float,
        ...marginLeft
    }
}