import React, {useCallback, useEffect, useState} from "react"
import {debounce} from "@mui/material";
import Box from "@mui/material/Box";
import RemoveIcon from '@mui/icons-material/Remove';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useDispatch } from "react-redux";
import {_commentListAlphabet} from "../../_mock";
import CustomAnswerList from "./CustomAnswerList";
import {Controller, useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {requestMemberComponentsSync} from "../../usefuls/helpers";
import {addParagraphPendingAction, addQuestionReq} from "../../store/Matters/mattersSlice";

const CustomCommentList = (
    {
        componentCommentList,
        componentIndex,
        answerId = null,
        answerMemberId,
        answerList,
        going_next,
        staticSection,
        questionData,
        ...customProps
    }
) => {
    const dispatch = useDispatch();
    const [initialDefaultSet, setInitialDefaultSet] = useState(false)
    const {
        control,
        setValue,
        getValues
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })
    const {matterId} = useParams();
    const addListItem = useCallback(() => {
        const updatedData = [...answerList];
        updatedData[componentIndex].commentList.push({id: Math.random(), value: ""})
        requestMemberComponentsSync(
            {
                customProps,
                matterId,
                updatedData,
                answerId: !!customProps.memberId ? answerMemberId === customProps.memberId ? answerId : null : answerId,
                dispatch,
                listItemHandle: true,
                going_next,
                staticSection
            }
        )
    }, [
        answerId,
        answerList,
        answerMemberId,
        componentIndex,
        customProps,
        dispatch,
        going_next,
        matterId,
        staticSection
    ]);

    const removeListItem = useCallback((index) => {
        const updatedData = [...answerList];
        updatedData[componentIndex].commentList =
            updatedData[componentIndex].commentList.filter((_, uuuu) => index !== uuuu)
        requestMemberComponentsSync(
            {
                customProps,
                matterId,
                updatedData,
                answerId: !!customProps.memberId ? answerMemberId === customProps.memberId ? answerId : null : answerId,
                dispatch,
                listItemHandle: true,
                going_next,
                staticSection
            }
        )
    }, [
        answerId,
        answerList,
        answerMemberId,
        componentIndex,
        customProps,
        dispatch,
        going_next,
        matterId,
        staticSection
    ])

    useEffect(() => {
        if (!initialDefaultSet && componentCommentList) {
            setValue("commentList", componentCommentList)
            setInitialDefaultSet(true);
        }
    }, [componentCommentList, initialDefaultSet, setValue]);

    const debouncedOnChange = debounce(({target: {id: comListId, value: comListVal}}) => {
        const updatedList = [...componentCommentList].map(cListItem => {
            if (String(cListItem.id) === String(comListId)) {
                return {
                    id: cListItem.id,
                    value: comListVal
                }
            }
            return cListItem
        })
        let updatedData = [...answerList];
        updatedData[componentIndex] = {commentList: updatedList};
        requestMemberComponentsSync(
            {
                customProps,
                matterId,
                updatedData,
                answerId: !!customProps.memberId ? answerMemberId === customProps.memberId ? answerId : null : answerId,
                dispatch,
                going_next,
                staticSection
            }
        )
    }, 500);

    const addQuestionBody = {
        position: questionData?.lastPosition + 1,
        dynamic_section_id: questionData?.componentInfo?.dynamic_section_id,
        is_dynamic: true,
        matter_id: +matterId,
        after_question_id: +questionData?.lastQuestionId,
        act_id: questionData?.actId,
        for_each_member: !(!!questionData?.specialQuestion),
        ...(!!questionData?.componentInfo?.parent_question_id
            ? {parent_question_id: questionData?.componentInfo?.parent_question_id}
            : {})
    }

    const incidentHeadingHandle = async (listItemIndex) => {
        const { payload } = await dispatch(addQuestionReq({
            title: "Added incident",
            description: "",
            is_user_defined: 1,
            heading: null,
            ...addQuestionBody
        }))
        if (payload.status === "success") {
            setTimeout(() => {
                dispatch(addParagraphPendingAction(false))
            }, 1000)
            if (!!getValues().commentList[listItemIndex].value) {
                requestMemberComponentsSync(
                    {
                        customProps: {
                            ...customProps,
                            questionId: payload.data.id,
                            parent_question_id: payload.data.parent_question_id
                        },
                        matterId,
                        updatedData: null,
                        answerId: null,
                        dispatch,
                        going_next: true,
                        staticSection,
                        listItemHandle: false,
                        heading: getValues().commentList[listItemIndex].value
                    }
                )
            }
        }
    }
    return (
        <Stack gap={2} mt="20px">
            <Typography>Comment List</Typography>
            {componentCommentList.map(({id: elemId}, listItemIndex) => {
                return <Box display="flex" alignItems="center">
                    <Typography>{_commentListAlphabet[listItemIndex]}</Typography>
                    <Controller
                        control={control}
                        name={`commentList.${listItemIndex}.value`}
                        render={({field: {onChange, value, ref, onBlur}}) => (
                            <textarea
                                name="commentList"
                                id={elemId}
                                style={{ marginLeft: "10px", fontStyle: "italic", width: "65%", height: "40px" }}
                                cols="30"
                                rows="10"
                                placeholder="Text"
                                value={value}
                                onBlur={onBlur}
                                onChange={(e) => {
                                    onChange(e);
                                    debouncedOnChange(e)
                                }}
                                ref={ref}
                                autoComplete="off"
                            />
                        )}/>
                    {componentCommentList.length !== 1 && (
                        <Button onClick={() => {
                            removeListItem(listItemIndex)
                        }} sx={{backgroundColor: "grey.300", color: "black.200", ml: "10px"}}>
                            <RemoveIcon/>
                        </Button>
                    )}
                    {listItemIndex === componentCommentList.length - 1 && (
                        <Button onClick={addListItem}
                                sx={{backgroundColor: "info.dark", color: "common.white", ml: "10px"}}>
                            +Add
                        </Button>
                    )}
                    {questionData?.componentInfo?.dynamic_section_id && (
                        // <Button onClick={() => incidentHeadingHandle(listItemIndex)} sx={{backgroundColor: "info.dark", color: "common.white", ml: "10px"}}>
                        //     Convert to an incident
                        // </Button>
                        <></>
                    )}
                </Box>
            })}
            <CustomAnswerList
                componentIndex={componentIndex}
                answerList={answerList}
                answerId={answerId}
                going_next={going_next}
                staticSection={staticSection}
                answerMemberId={answerMemberId}
                {...customProps}
            />
        </Stack>
    )
};

export default CustomCommentList;