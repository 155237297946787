import { configureStore } from '@reduxjs/toolkit'
import authSlice from "./Auth/authSlice";
import usersSlice from "./Users/usersSlice";
import mattersSlice from "./Matters/mattersSlice";

export const store = configureStore({
    reducer: {
        auth: authSlice,
        users: usersSlice,
        matters: mattersSlice
    }
})